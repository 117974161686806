import {
  makeAutoObservable, observable,
} from 'mobx';
import { cloneDeep } from 'lodash';
import request from 'utils/request';
import { array2tree } from 'utils/utils';
import staticMenus from 'config/menus';
import * as Serv from './app-server';

const staticPath2MenuLabel = {};
const staticRecursive = (menus) => {
  menus.forEach((menu) => {
    staticPath2MenuLabel[menu.routePath] = menu.label;
    Array.isArray(menu.children) && staticRecursive(menu.children);
  });
};
staticRecursive(staticMenus);

class AppStore {
  menuList = [];

  path2Menu = {};

  path2MenuLabel = staticPath2MenuLabel;

  tags = [];

  areaTree = []; // 省市区

  areaMap = {}; // 省市id到省市对象的映射

  curUser = {};

  tictokAuth = {}; // 抖音扫码路径及扫码成功后跳转路径

  globalLoading = false;

  windowWidth = window.innerWidth;

  constructor() {
    makeAutoObservable(this, {
      provinceCities: observable.shallow,
      path2Menu: observable.shallow,
      provinceCityId2Item: observable.shallow,
      curUser: observable.shallow,
      tictokAuth: observable.shallow,
      path2MenuLabel: observable.shallow,
    });
  }

  setMenuList(list) { // 静态或通过接口获取的
    const path2Menu = {};
    const path2Label = {};
    this.menuList = cloneDeep(list);

    const traverseMenu = (arr, parent) => {
      arr.forEach((menuItem) => {
        menuItem.path = menuItem.routePath || menuItem.href || '';
        menuItem.label = menuItem.label || menuItem.title || menuItem.name;

        if (menuItem.path.startsWith('/')) {
          menuItem.fullPath = menuItem.path;
        } else if (parent) {
          menuItem.fullPath = `${parent.fullPath}/${menuItem.path}`;
        }
        menuItem.parent = parent;

        path2Menu[menuItem.fullPath] = menuItem;
        path2Label[menuItem.fullPath] = menuItem.label;

        Array.isArray(menuItem.children) && traverseMenu(menuItem.children, menuItem);
      });
    };
    traverseMenu(this.menuList);

    this.path2Menu = path2Menu;
    this.path2MenuLabel = { ...staticPath2MenuLabel, ...path2Label };
  }

  closeTab(pathname) {
    let tagIndex = 0;
    let nextActiveTag = null;
    const tags = this.tags.filter((t, index) => {
      if (t.pathname !== pathname) {
        return true;
      }
      tagIndex = index;
    });
    if (tagIndex > 0) { // 激活左侧的
      nextActiveTag = this.tags[tagIndex - 1];
    } else { // 激活右侧的
      // eslint-disable-next-line prefer-destructuring
      nextActiveTag = tags[0];
    }
    this.tags = [...tags];
    return nextActiveTag;
  }

  setTabName(pathname, label) { // todo setTabName有可能在setMenuList之前执行
    if (this.path2Menu[pathname]) {
      this.path2Menu[pathname].label = label;
      this.path2Menu = { ...this.path2Menu };
    }
    if (this.path2MenuLabel[pathname]) {
      this.path2MenuLabel = {
        ...this.path2MenuLabel,
        [pathname]: label,
      };
    }
  }

  async getArea() {
    if (this.areaTree.length) {
      return;
    }
    try {
      const obj = {};
      const { data } = await request({
        url: '/yinweifuli-system-web/area/list',
        params: {
          levelMax: 2,
        },
      });
      data?.forEach((area) => {
        area.label = area.name;
        area.value = area.areaId;
        obj[area.areaId] = area;
      });
      this.areaTree = array2tree(data, { parentId: 'pid', id: 'areaId', mostTopId: '0' });
      this.areaMap = obj;
    } catch (e) {
    }
  }

  async checkOldPassword(params) {
    const { data } = await Serv.checkOldPassword(params);
    return data;
  }

  async updatePassword(params) {
    await Serv.updatePassword(params);
  }
}

export default new AppStore();
