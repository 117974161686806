import config from 'config/config';
import { isEmpty, isNumber } from 'lodash';

let accessibleBtns = [];

export function array2tree(list, {
  parentId = 'parentId', id = 'id', mostTopId = '0', filter,
}) {
  const result = [];
  const id2item = {};
  list.forEach((item) => {
    id2item[item[id]] = item;
    if (item[parentId] === mostTopId) {
      result.push(item);
    }
  });
  list.filter((item) => (filter ? filter(item) : true)).forEach((item) => {
    const parentItem = id2item[item[parentId]];
    if (parentItem) {
      parentItem.children = parentItem.children || [];
      parentItem.children.push(item);
    }
  });
  return result;
}

export function tree2array(tree) {
  const result = [];
  const traverseTreeItem = (treeList) => {
    treeList.forEach((item) => {
      result.push(item);
      traverseTreeItem(item.children || []);
    });
  };
  traverseTreeItem(tree);
  return result;
}

export function checkBtnPermission(permCode) {
  if (!config.isCheckPermission) {
    return true;
  }
  if (!permCode) {
    return true;
  }
  if (!accessibleBtns?.length) {
    try {
      accessibleBtns = JSON.parse(localStorage.getItem(config.localData.accessibleBtns) || '[]');
    } catch (e) {
    }
  }
  return !!accessibleBtns?.includes?.(permCode);
}

export function getTreeItemById(tree, value, { id = 'id', children = 'children' }) {
  if (!Array.isArray(tree)) {
    return null;
  }
  let result = null;
  const traverse = (treeData) => {
    treeData.some((item) => {
      if (item[id] === value) {
        result = item;
        return true;
      }
      if (Array.isArray(item[children])) {
        traverse(item[children]);
      }
    });
  };
  traverse(tree);
  return result;
}

export function getDiffOfArr(arr1, arr2) { // 获取arr2相对arr1做什么的增删
  const add = [];
  const del = [];
  arr1.forEach((item) => {
    if (!arr2.includes(item)) {
      del.push(item);
    }
  });
  arr2.forEach((item) => {
    if (!arr1.includes(item)) {
      add.push(item);
    }
  });
  return { add, del };
}

export const phoneRegexp = /^1[3-9]\d{9}$/;

export function getAgeForIdCard(identityCard) { // 从身份正号获取年龄， 参考: https://www.jianshu.com/p/5052f6ddcf9e
  const len = (`${identityCard}`).length;
  let strBirthday = '';
  if (len == 18) { // 处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday = `${identityCard.substr(6, 4)}/${identityCard.substr(10, 2)}/${identityCard.substr(12, 2)}`;
  }
  if (len == 15) {
    let birthdayValue = '';
    birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
    if (parseInt(birthdayValue, 10) < 10) {
      strBirthday = `20${identityCard.substr(6, 2)}/${identityCard.substr(8, 2)}/${identityCard.substr(10, 2)}`;
    } else {
      strBirthday = `19${identityCard.substr(6, 2)}/${identityCard.substr(8, 2)}/${identityCard.substr(10, 2)}`;
    }
  }
  // 时间字符串里，必须是“/”
  const birthDate = new Date(strBirthday);
  const nowDateTime = new Date();
  let age = nowDateTime.getFullYear() - birthDate.getFullYear();
  // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

export function transformNum(value, needHandleUnit = false) { // 参考 https://www.orchome.com/1877
  const newValue = ['', ''];
  let fr = 1000;
  let num = 3;
  let hasMinus = false;

  if (value < 0) {
    hasMinus = true;
  }

  while (Math.abs(value) / fr >= 1) {
    fr *= 10;
    num += 1;
  }
  if (num <= 4) { // 千
    newValue[1] = '千';
    newValue[0] = `${hasMinus ? '-' : ''}${parseInt(Math.abs(value) / 1000, 10)}`;
  } else if (num <= 8) { // 万
    const text1 = parseInt(num - 4, 10) / 3 > 1 ? '千万' : '万';
    const fm = text1 === '万' ? 10000 : 10000000;
    newValue[1] = text1;
    newValue[0] = `${hasMinus ? '-' : ''}${(Math.abs(value) / fm).toFixed(2)}`;
  } else if (num <= 16) { // 亿
    let text1 = (num - 8) / 3 > 1 ? '千亿' : '亿';
    text1 = (num - 8) / 4 > 1 ? '万亿' : text1;
    text1 = (num - 8) / 7 > 1 ? '千万亿' : text1;
    let fm = 1;
    if (text1 === '亿') {
      fm = 100000000;
    } else if (text1 === '千亿') {
      fm = 100000000000;
    } else if (text1 === '万亿') {
      fm = 1000000000000;
    } else if (text1 === '千万亿') {
      fm = 1000000000000000;
    }
    newValue[1] = text1;
    newValue[0] = `${hasMinus ? '-' : ''}${(Math.abs(value) / fm).toFixed(2)}`;
  }
  if (Math.abs(value) < 1000) {
    newValue[1] = '';
    newValue[0] = `${hasMinus ? '-' : ''}${Math.abs(value)}`;
  }

  return needHandleUnit ? newValue : newValue.join('');
}

export function numberWithCommas(value, formatter) { // 千分位
  if (value === null || value === undefined) {
    return '';
  }
  try {
    // return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    return Number(value).toLocaleString(); // safari not support regex group
  } catch (e) {
  }
}

export function transformNumber(value, needHandleUnit = false) {
  if (Math.abs(value || 0) > 10000) {
    return transformNum(value, needHandleUnit);
  }
  return numberWithCommas(isEmptyExceptNumber(value) ? '' : value);
}

export function formatChannelAuthUrl(authInfo, channel) {
  if (`${channel}` === '1') {
    return `${authInfo.douyinUrl}?client_key=${authInfo.clientKey}&scope=${authInfo.scope}&response_type=${authInfo.responseType}&redirect_uri=${authInfo.redirectUri}/third-auth/tictok.html`;
  }
}

// 如果不是数值并且为空(包括空数组，空对象，空字符串，undefined，null)
export function isEmptyExceptNumber(value) {
  return !isNumber(value) && isEmpty(value);
}

export function formatDuration(seconds, format = ':') { // 把秒转化为时分秒字符串, format: ':'或 'hms', 或为‘:’如67秒 -> 00:01:07, 若为hms则为1m6s
  if (seconds === null || isNaN(seconds)) {
    return '';
  }
  seconds = Math.floor(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds - 3600 * h) / 60);
  const s = seconds - 3600 * h - 60 * m;
  if (format === ':') {
    return `${`0${h}`.slice(-2)}:${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
  }
  let res = '';
  if (h > 0) {
    res += `${h}h`;
  } else if (m > 0) {
    res += `${m}m`;
  } else if (s > 0) {
    res += `${s}s`;
  }
  return res;
}

export function getDefaultPath() {
  let accessibleMenusTree = [];
  const pathList = [];
  try {
    accessibleMenusTree = JSON.parse(localStorage.getItem(config.localData.accessibleMenus));
    const recursiveArrTree = function (arr, parent) {
      arr.forEach((item) => {
        item.routePath = `${parent?.component ?? ''}/${item.component}`.replace(/\/{2,}/g, '/');
        if (Array.isArray(item.childrens)) {
          recursiveArrTree(item.childrens, item);
        }
      });
    };
    recursiveArrTree(accessibleMenusTree);
    const recursiveArr = (arr) => {
      arr.forEach((item) => {
        if (item.routePath && item.routePath !== '/') {
          pathList.push(item.routePath.replace(/\/{2,}/g, '/'));
        }
        if (Array.isArray(item.childrens)) {
          recursiveArr(item.childrens);
        }
      });
    };
    recursiveArr(accessibleMenusTree);
  } catch (e) {
  }
  return !pathList.length || pathList.includes(config.defaultRoutePath) ? config.defaultRoutePath : pathList[0];
}

export function cachePageSize(pathname, obj) { // obj为{current: xxx, size}, 如果传入了两个参数则是设置(set), 如果只传入了一个参数则是查询(get)
  const memoryCacheKey = 'list_current_size';
  window[memoryCacheKey] = window[memoryCacheKey] || {};
  if (obj) {
    window[memoryCacheKey][pathname] = obj;
  }
  return window[memoryCacheKey][pathname] || {};
}

// 金额格式化 value：数值 n:几位小数
export function formatMoney(value, n) {
  const str = `${(value / 1).toFixed(n)}`;
  const intSum = str.substring(0, str.indexOf('.')).replace(/\B(?=(?:\d{3})+$)/g, ',');
  const dot = str.substring(str.length, str.indexOf('.'));
  return intSum + dot;
}

export function moneyFormatter(num) {
  if (num === null || num === undefined) {
    return num;
  }
  return Number(num).toLocaleString('zh-CN', { style: 'currency', currency: 'CNY' });
}

export function decimalNumToFixed(num) { // num 是 Decimal.js 实例
  const numStr = num.toString();
  if (numStr === '0') {
    return numStr;
  }
  if (numStr < '0.00001') {
    return num.toFixed(6);
  }
  if (numStr < '0.0001') {
    return num.toFixed(5);
  }
  if (numStr < '0.001') {
    return num.toFixed(4);
  }
  if (numStr < '0.01') {
    return num.toFixed(3);
  }
  return num.toFixed(2);
}

export function downloadOssFile(ossFile, fileName) {
  fileName = fileName || ossFile.split('/').pop();
  fetch(`${ossFile}?not-cache-${Date.now()}`).then((resp) => resp.blob()).then((blob) => {
    const objUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = objUrl;
    a.download = fileName;
    a.click();
  });
}

export async function getSplitImgBase64Url(imgItem, ossProcess = '?x-oss-process=image/resize,w_500,q_60') {
  if (imgItem.uploadFlag === 1) { // 上传的
    return imgItem.ossUrl + ossProcess;
  }
  // mj 生成的
  let url = '';
  if (imgItem.ossUrl.includes('.aliyuncs.com')) { // 已上传到oss
    url = `${imgItem.ossUrl}${ossProcess}`;
  } else { // 还未上传到oss
    url = imgItem.ossUrl;
  }
  const { index } = imgItem;
  let img = new window.Image();
  img.setAttribute('crossOrigin', '');
  img.style.visibility = 'hidden';
  img.src = url;
  const base64Url = await new Promise((resolve) => {
    img.onload = function () {
      const { naturalWidth: imageWidth, naturalHeight: imageHeight } = img;

      const pos = index !== null ? [
        { top: 0, left: 0 },
        { top: 0, left: (imageWidth / 2) | 0 },
        { left: 0, top: imageHeight / 2 },
        { left: imageWidth / 2, top: imageHeight / 2 },
      ][index - 1] : {
        left: 0, top: 0, width: imageWidth, height: imageHeight,
      };

      const width = pos.width || imageWidth / 2;
      const height = pos.height || imageHeight / 2;
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, pos.left, pos.top, width, height, 0, 0, width, height);
      const base64 = canvas.toDataURL();
      resolve(base64);
    };
  });
  img = null;
  return base64Url;
}

const imgInfoMap = {};

export async function getCropImgUrl(imgItem) {
  const { ossUrl } = imgItem;
  if (imgItem.uploadFlag === 1) {
    return ossUrl;
  }
  const { index } = imgItem;
  if (index === null) {
    return ossUrl;
  }
  let resultUrl = ossUrl;
  resultUrl += '?x-oss-process=image/crop,'; // doc https://help.aliyun.com/document_detail/44693.html?spm=a2c4g.44975.0.0
  let resp = imgInfoMap[imgItem.recordId];
  if (!resp) {
    resp = await fetch(`${imgItem.ossUrl}?x-oss-process=image/info`).then(async (res) => await res.json());
    imgInfoMap[imgItem.recordId] = resp;
  }
  const { ImageWidth: { value: width }, ImageHeight: { value: height } } = resp;
  if (index === 1) { // 左上
    resultUrl += `x_0,y_0,w_${width / 2},h_${height / 2}`;
  } else if (index === 2) { // 右上
    resultUrl += `x_${width / 2},y_0,w_${width / 2},h_${height / 2}`;
  } else if (index === 3) { // 左下
    resultUrl += `x_0,y_${height / 2},w_${width / 2},h_${height / 2}`;
  } else { // 右下
    resultUrl += `x_${width / 2},y_${height / 2},w_${width / 2},h_${height / 2}`;
  }
  return resultUrl;
}
