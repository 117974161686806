import { makeAutoObservable, observable } from 'mobx';
import { getMenuTree, updateEnableFlag } from './menu-mgnt-serv';

class Store {
  menuTree = []

  menuMap = {}

  biz: Record<string, never> = {}

  selectMenuId = '' // 菜单树中选中的菜单ID

  constructor() {
    makeAutoObservable(this, {
      menuTree: observable.shallow,
      menuMap: observable.shallow,
      biz: observable.shallow,
    });
  }

  async updateEnableFlag(ids: string[], enableFlag) {
    try {
      await updateEnableFlag({ ids, enableFlag });
      this.biz.refreshListDatas?.(true);
    } catch (e) {
    }
  }

  async getMenuTree() {
    try {
      const { data } = await getMenuTree({ type: 0 });
      const menuMap = {};
      const recursiveArr = (arr) => {
        arr.forEach((item, idx) => {
          item.title = item.name || item.label || '';
          item.key = item.menuId;
          item.value = item.menuId;
          item.children = item.childrens;
          item.nextId = arr[idx + 1]?.menuId;
          item.prevId = arr[idx - 1]?.menuId;
          if (idx === 0) {
            item.isFirstChild = true;
          }
          if (idx === arr.length - 1) {
            item.isLastChild = true;
          }
          menuMap[item.key] = item;
          if (Array.isArray(item.children)) {
            item.children = recursiveArr(item.children);
          }
        });
        return arr.filter((item) => item.isTreeShow);
      };
      this.menuTree = recursiveArr(data);
      this.menuMap = menuMap;
    } catch (e) {
    }
    this.biz?.refreshListDatas?.();
  }
}
export default new Store();
