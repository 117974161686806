import { makeAutoObservable, observable } from 'mobx';
import { array2tree } from 'utils/utils';
import moment from 'moment';
import { grantTypeMap } from 'pages/consts';
import {
  getDetail, getCategoryList, getShopList, getCommodityList, getAllShopList,
} from './coupon-batch-add-serv';

class Store {
  detail = {}

  values = {}

  categoryMap = {}

  frontCategoryTree = []

  scopeEntityList = []

  allShopList = []

  shopMap = {}

  constructor() {
    makeAutoObservable(this, {
      frontCategoryTree: observable.shallow,
      categoryMap: observable.shallow,
      scopeEntityList: observable.shallow,
      allShopList: observable.shallow,
      shopMap: observable.shallow,
      detail: observable.shallow,
    });
  }

  initState() {
    this.values = {};
    this.detail = {};
    this.scopeEntityList = [];
  }

  async getCategoryTree() {
    try {
      const { data } = await getCategoryList({ size: 1000, current: 1 });
      const categoryList = data || [];
      const frontList = [];
      const backList = [];
      const categoryMap = {};
      categoryList.sort((a, b) => a.sort - b.sort);
      categoryList.forEach((item) => {
        item.parentId = item.parentCategoryId;
        categoryMap[item.key] = item;
        if (item.type === 1) {
          frontList.push(item);
        } else if (item.type === 2) {
          backList.push(item);
        }
      });
      const frontTree = array2tree(frontList, { parentId: 'parentCategoryId', id: 'categoryId', mostTopId: '' });
      this.frontCategoryTree = frontTree;
      this.categoryMap = categoryMap;
    } catch (e) {
    }
  }

  async getAllShopList() {
    const shopMap = {};
    const { data } = await getAllShopList();
    data?.forEach((shop) => {
      shopMap[shop.shopId] = shop;
    });
    this.allShopList = data || [];
    this.shopMap = shopMap;
  }

  async getDetail(id, form) {
    const self = this;
    const { data } = await getDetail(id);
    if (data) {
      let receiveTime = null;
      if (data.receiveStartTime && data.receiveEndTime) {
        receiveTime = [
          moment(data.receiveStartTime, 'YYYY-MM-DD HH:mm:ss'),
          moment(data.receiveEndTime, 'YYYY-MM-DD HH:mm:ss'),
        ];
      }
      data.receiveTime = receiveTime;
      data.couponActivityUpdateCmd = {
        name: data.name,
        quota: data.quota,
        faceValue: data.faceValue,
        grantType: data.grantType,
        description: data.description,
        introduction: data.introduction,
      };
      data.couponActivityUseRuleUpdateCmds = data.couponActivityUseRuleDTOS?.map?.((item) => {
        if (item.ruleType === 30) {
          if (item.ruleStartTime && item.ruleEndTime) {
            item.ruleTime = [
              moment(item.ruleStartTime, 'YYYY-MM-DD HH:mm:ss'),
              moment(item.ruleEndTime, 'YYYY-MM-DD HH:mm:ss'),
            ];
          }
        }
        return item;
      });
      data.couponActivityReceiveRuleUpdateCmds = data.couponActivityReceiveRuleDTOS || [];
      data.couponActivityScopeUpdateParams = data.couponActivityScopeDTOS?.slice(0, 1)?.map((item) => { // todo slice
        item.couponActivityScopeItemParams = {}; // 辅助formItem
        if (data.grantType === 1) {
          item.couponActivityScopeItemParams.grantType1AssociateMerchantIds = item.couponActivityScopeItemDTOS?.filter((item) => item.associateMerchantId).map((item) => item.associateMerchantId);
          item.couponActivityScopeItemParams.grantType1AssociateMerchantIds = Array.from(new Set(item.couponActivityScopeItemParams.grantType1AssociateMerchantIds));
        }
        if (data.grantType === 2) {
          item.couponActivityScopeItemParams.grantType2AssociateMerchantIds = item.couponActivityScopeItemDTOS?.filter((item) => item.associateMerchantId).map((item) => item.associateMerchantId);
          item.couponActivityScopeItemParams.associateShopIds = item.couponActivityScopeItemDTOS?.filter((item) => item.associateShopId)?.map((item) => item.associateShopId);
          item.couponActivityScopeItemParams.grantType2AssociateMerchantIds = Array.from(new Set(item.couponActivityScopeItemParams.grantType2AssociateMerchantIds));
          item.couponActivityScopeItemParams.associateShopIds = Array.from(new Set(item.couponActivityScopeItemParams.associateShopIds));
        }
        if (item.scope === 20 || item.scope === 13) { // 目前couponActivityScopeDTOS只有一个元素，如果以后扩展到多个元素后，要调整
          self.getScopeEntityList('shop', item.couponActivityScopeItemDTOS?.map((scopeItem) => scopeItem.associateShopId), data.grantType, form);
        }
        if (item.scope === 22 || item.scope === 2) {
          self.getScopeEntityList('category', item.couponActivityScopeItemDTOS?.map((scopeItem) => scopeItem.associateCategoryId), data.grantType, form);
        }
        if (item.scope === 3 || item.scope === 14 || item.scope === 23) {
          self.getScopeEntityList('commodity', item.couponActivityScopeItemDTOS?.map((scopeItem) => scopeItem.associateCommodityId), data.grantType, form);
        }
        return item;
      });
      this.values = data;
      this.detail = data;
    }
  }

  async getScopeEntityList(type, ids, grantType, form) { // type: merchant, shop, commodity
    if (!ids.length) {
      return;
    }
    if (type === 'shop') {
      const { data } = await getShopList({ current: 1, size: ids.length, shopIds: ids });
      this.scopeEntityList = data.records || [];
    }
    if (type === 'category') {
      const { data } = await getCategoryList({ current: 1, size: ids.length, categoryIds: ids });
      if (grantType === 0) { // 平台类目
        this.scopeEntityList = data || [];
      }
    }
    if (type === 'commodity') {
      const { data } = await getCommodityList({ current: 1, size: ids.length, commodityIds: ids });
      this.scopeEntityList = data?.records || [];
    }
  }
}
export default new Store();
