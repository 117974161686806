import request from 'utils/request';

export function getDetail(id) {
  return request({
    url: `/yinweifuli-user-web/customer-user/get-by-id/${id}`,
  });
}
export function getOrderList(params) {
  return request({
    url: '/yinweifuli-order-web/order/find-page',
    params,
  });
}
export function getAftersaleOrderList(params) {
  return request({
    url: '/yinweifuli-order-web/order-after-sales/find-page',
    params,
  });
}

export function getShopList(params) {
  return request({
    url: '/yinweifuli-shop-web/shop/find-page',
    params,
  });
}

export function getAddressList(params) {
  return request({
    url: '/yinweifuli-user-web/user-address/list',
    params,
  });
}
