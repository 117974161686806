import { makeAutoObservable } from 'mobx';

class Store {
  values = {};

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Store();
