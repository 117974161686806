import { makeAutoObservable, observable } from 'mobx';
import { getDetail, getOrderDetail, getLogList } from './aftersale-order-detail-serv';

class Store {
  detail = {}

  orderDetail = {}

  logList = []

  constructor() {
    makeAutoObservable(this, {
      detail: observable.shallow,
      orderDetail: observable.shallow,
      logList: observable.shallow,
    });
  }

  async getDetail(id) {
    const { data } = await getDetail(id);
    this.detail = data;
  }

  async getOrderDetail(orderId) {
    const { data } = await getOrderDetail(orderId);
    this.orderDetail = data;
  }

  async getLogList(params) {
    const { data } = await getLogList(params);
    this.logList = data || [];
  }
}
export default new Store();
