import { makeAutoObservable, observable } from 'mobx';
import { getAsyncData } from './coupon-batch-list-serv';

class Store {
  test = ''

  biz = {}

  constructor() {
    makeAutoObservable(this, {
      biz: observable.shallow,
    });
  }

  async getAsyncData() {
    const { success, data } = await getAsyncData();
    if (success) {
      this.test = data || [];
    }
  }
}
export default new Store();
