import request from 'utils/request';

export function checkOldPassword(params) {
  return request({
    url: '/yinweifuli-system-web/current-user/check-old-password',
    method: 'GET',
    params,
  });
}

export function updatePassword(params) {
  return request({
    url: '/yinweifuli-system-web/current-user/update-password',
    method: 'PUT',
    data: params,
  });
}
