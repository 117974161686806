import request from 'utils/request';

export function getMenuTree(params) {
  return request({
    url: '/yinweifuli-system-web/menu/tree',
    method: 'GET',
    params,
  });
}

export function updateEnableFlag(params: {ids: string[], enableFlag: number}) {
  return request({
    url: '/yinweifuli-system-web/menu/update-enable-flag',
    method: 'PUT',
    data: params,
  });
}
