import { makeAutoObservable, observable } from 'mobx';
import { getDetail, getRoleList } from './user-detail-serv';

class Store {
  detail = {}

  roleList = []

  constructor() {
    makeAutoObservable(this, {
      detail: observable.shallow,
      roleList: observable.shallow,
    });
  }

  async getDetail(id) {
    try {
      const { data } = await getDetail(id);
      this.detail = data;
    } catch (e) {

    }
  }

  async getRoleListOfCurUser(params) {
    try {
      const { data } = await getRoleList(params);
      this.roleList = data || [];
    } catch (e) {
    }
  }
}
export default new Store();
