import { makeAutoObservable, observable } from 'mobx';
import { getDetail } from './coupon-receive-list-serv';

class Store {
  detail = {}

  biz = {}

  constructor() {
    makeAutoObservable(this, {
      biz: observable.shallow,
    });
  }

  async getDetail(id) {
    const { success, data } = await getDetail(id);
    if (success) {
      this.detail = data;
    }
  }
}
export default new Store();
