import { makeAutoObservable, observable } from 'mobx';
import { getAsyncData } from './brand-mgnt-serv';

class Store {
  biz = {};

  createVisible = false;

  constructor() {
    makeAutoObservable(this, {
      biz: observable.shallow,
    });
  }

  async getAsyncData() {
    const { success, data } = await getAsyncData();
    if (success) {
      this.test = data || [];
    }
  }
}
export default new Store();
