import { makeAutoObservable, observable } from 'mobx';
import {
  createRemark, getAnswerDetail, getAnswerTabList, getRemarkList,
} from './questionnaire-answer-serv';

class Store {
  answerDetail = null

  answerTabList = []

  remarkList = []

  constructor() {
    makeAutoObservable(this, {
      answerDetail: observable.shallow,
      answerTabList: observable.shallow,
    });
  }

  async getAnswerTabList(params) {
    const { data } = await getAnswerTabList(params);
    this.answerTabList = data;
    return data?.[0]?.questionSubmitDataId;
  }

  async getAnswerDetail(questionSubmitDataId) {
    const { data } = await getAnswerDetail(questionSubmitDataId);
    this.answerDetail = data;
  }

  async getRemarkList(params) {
    const { data } = await getRemarkList(params);
    this.remarkList = data;
  }

  async createRemark(params) {
    await createRemark(params);
  }
}
export default new Store();
