import { makeAutoObservable, observable } from 'mobx';
import {
  getConditions, getMatchSceneList, getScreenConditionList, getMatchSortList, getMatchAlgorithm, getChannelList,
} from './match-rule-serv';

class Store {
    biz = {};

    createVisible = false;

    conditions = []

    matchScenes = []

    channelList= []

    chosenScreenConditions = {}

    chosenSortingConditions = []

    store_apply_time_scores = {
      matchSortId: '',
      code: 'store_apply_time',
      matchConditionId: 100,
      matchSortConditionParam: {
        type: 4,
        score: [10, 8, 5, 4, 2, 0],
      },
    }

    user_apply_time_scores = {
      matchSortId: '',
      code: 'user_apply_time',
      matchConditionId: 101,
      matchSortConditionParam: {
        type: 4,
        score: [10, 8, 5, 4, 2, 0],
      },
    }

    user_distance_scores = {
      matchSortId: '',
      code: 'user_distance',
      matchConditionId: 102,
      matchSortConditionParam: {
        type: 4,
        score: [10, 8, 5, 4, 2, 0],
      },
    }

    step = 2

    selectedScene = '1'

    screenConditionList = [] // 目前已保存的初筛条件

    sortingConditionList = [] // 目前已保存的排序条件

    constructor() {
      makeAutoObservable(this, {
        conditions: observable.shallow,
        chosenScreenConditions: observable.shallow,
        chosenSortingConditions: observable.shallow,
        screenConditionList: observable.shallow,
        sortingConditionList: observable.shallow,
        channelList: observable.shallow,
      });
    }

    async getConditions() {
      const { data } = await getConditions({});
      this.conditions = data;
    }

    async getMatchSceneList() {
      const { data } = await getMatchSceneList();
      this.matchScenes = data;
      this.selectedScene = data?.[0].matchSceneId;
      this.getScreenConditionList(this.selectedScene);
      this.getMatchSortList(this.selectedScene);
      this.getMatchAlgorithm(this.selectedScene);
    }

    async getScreenConditionList(matchSceneId) { // 初筛设置的条件
      const { conditions } = this;
      const conditionMap = {};
      conditions.forEach((cItem) => {
        conditionMap[cItem.code] = cItem;
      });
      const { data } = await getScreenConditionList({ matchSceneId });
      this.screenConditionList = data;
      const chosenObj = {};
      data.forEach((item) => {
        let matchScreenConditionParam = {};
        try {
          matchScreenConditionParam = JSON.parse(item.jsonData);
        } catch (e) {
        }
        chosenObj[item.code] = {
          ...conditionMap[item.code],
          matchScreenConditionParam,
        };
      });
      this.chosenScreenConditions = chosenObj;
    }

    async getMatchSortList(matchSceneId) {
      const _t = this;
      const { conditions } = this;
      const conditionMap = {};
      conditions.forEach((cItem) => {
        conditionMap[cItem.code] = cItem;
      });
      const { data } = await getMatchSortList({ matchSceneId });
      const chosenItems = [];
      data.forEach((sItem) => {
        let matchSortConditionParam = {};
        try {
          matchSortConditionParam = JSON.parse(sItem.jsonData);
        } catch (e) {
        }
        if (['store_apply_time', 'user_apply_time', 'user_distance'].includes(sItem.code)) {
          _t[`${sItem.code}_scores`] = {
            ..._t[`${sItem.code}_scores`],
            matchConditionId: sItem.matchConditionId,
            matchSortId: sItem.matchSortId,
            matchSortConditionParam: {
              type: 4,
              score: matchSortConditionParam.score || [],
            },
          };
        } else {
          chosenItems.push({
            ...conditionMap[sItem.code],
            matchSortParam: {
              matchSortId: sItem.matchSortId,
              matchConditionId: sItem.matchConditionId,
              code: sItem.code,
              matchSortConditionParam,
            },
          });
        }
      });
      this.sortingConditionList = data;
      this.chosenSortingConditions = chosenItems;
    }

    async getMatchAlgorithm(matchSceneId) {
      const { data } = await getMatchAlgorithm(matchSceneId);
    }

    async getChannelList() {
      const { data } = await getChannelList({ channelType: 0 });
      this.channelList = data;
    }
}
export default new Store();
