import { makeAutoObservable, observable } from 'mobx';
import {
  getCategoryList, getCommodityList, getShopList, getAllShopList,
} from 'pages/coupon-mgnt/coupon-batch-add/coupon-batch-add-serv';
import { getDetail, getAllMerchants } from './coupon-detail-view-serv';

class Store {
  detail = {}

  scopeEntityList = []

  shopMap = {}

  merchantMap = {}

  constructor() {
    makeAutoObservable(this, {
      detail: observable.shallow,
      scopeEntityList: observable.shallow,
      shopMap: observable.shallow,
      merchantMap: observable.shallow,
    });
  }

  async getDetail(id) {
    const self = this;
    const { data } = await getDetail(id);
    this.detail = data;
    data.couponActivityScopeDTOS?.slice(0, 1)?.map((item) => { // todo slice
      item.couponActivityScopeItemParams = {}; // 辅助formItem
      if (data.grantType === 1) {
        item.couponActivityScopeItemParams.grantType1AssociateMerchantIds = item.couponActivityScopeItemDTOS?.filter((item) => item.associateMerchantId).map((item) => item.associateMerchantId);
        item.couponActivityScopeItemParams.grantType1AssociateMerchantIds = Array.from(new Set(item.couponActivityScopeItemParams.grantType1AssociateMerchantIds));
      }
      if (data.grantType === 2) {
        item.couponActivityScopeItemParams.grantType2AssociateMerchantIds = item.couponActivityScopeItemDTOS?.filter((item) => item.associateMerchantId).map((item) => item.associateMerchantId);
        item.couponActivityScopeItemParams.associateShopIds = item.couponActivityScopeItemDTOS?.filter((item) => item.associateShopId)?.map((item) => item.associateShopId);
        item.couponActivityScopeItemParams.grantType2AssociateMerchantIds = Array.from(new Set(item.couponActivityScopeItemParams.grantType2AssociateMerchantIds));
        item.couponActivityScopeItemParams.associateShopIds = Array.from(new Set(item.couponActivityScopeItemParams.associateShopIds));
      }
      if (item.scope === 20 || item.scope === 13) { // 目前couponActivityScopeDTOS只有一个元素，如果以后扩展到多个元素后，要调整
        self.getScopeEntityList('shop', item.couponActivityScopeItemDTOS?.map((scopeItem) => scopeItem.associateShopId), data.grantType);
      }
      if (item.scope === 22 || item.scope === 2) {
        self.getScopeEntityList('category', item.couponActivityScopeItemDTOS?.map((scopeItem) => scopeItem.associateCategoryId), data.grantType);
      }
      if (item.scope === 3 || item.scope === 14 || item.scope === 23) {
        self.getScopeEntityList('commodity', item.couponActivityScopeItemDTOS?.map((scopeItem) => scopeItem.associateCommodityId), data.grantType);
      }
      return item;
    });
  }

  async getAllShopList() {
    const shopMap = {};
    const { data } = await getAllShopList();
    data?.forEach((shop) => {
      shopMap[shop.shopId] = shop;
    });
    this.shopMap = shopMap;
  }

  async getAllMerchants() {
    const { data } = await getAllMerchants();
    const merchantMap = {};
    data.forEach((item) => {
      merchantMap[item.merchantId] = item;
    });
    this.merchantMap = merchantMap;
  }

  async getScopeEntityList(type, ids, grantType) { // type: merchant, shop, commodity
    if (!ids.length) {
      return;
    }
    if (type === 'shop') {
      const { data } = await getShopList({ current: 1, size: ids.length, shopIds: ids });
      this.scopeEntityList = data.records || [];
    }
    if (type === 'category') {
      const { data } = await getCategoryList({ current: 1, size: ids.length, categoryIds: ids });
      if (grantType === 0) { // 平台类目
        this.scopeEntityList = data || [];
      }
    }
    if (type === 'commodity') {
      const { data } = await getCommodityList({ current: 1, size: ids.length, commodityIds: ids });
      this.scopeEntityList = data?.records || [];
    }
  }
}
export default new Store();
