import request from 'utils/request';

export function getCategoryList(params) {
  return request({
    url: '/yinweifuli-shop-web/category/list',
    method: 'GET',
    params,
  });
}

export function getDetail(id) {
  return request({
    url: `/yinweifuli-marketing-web/coupon-activity/get-detail-by-id/${id}`,
  });
}

export function getShopList(params) {
  return request({
    url: '/yinweifuli-shop-web/shop/find-page',
    method: 'GET',
    params,
  });
}

export function getCommodityList(params) {
  return request({
    url: '/yinweifuli-shop-web/commodity/find-page',
    method: 'POST',
    data: params,
  });
}

export function getAllShopList(params = { current: 1, size: 1000 }) {
  return request({
    url: '/yinweifuli-shop-web/shop/list',
    params,
  });
}
