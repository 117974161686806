import React, { useEffect, useState } from 'react';
import {
  Form, Input, Button, message,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Cookie from 'js-cookie';
import { inject } from 'mobx-react';
import request from 'utils/request';
import config from 'config/config';
import { getDefaultPath } from 'utils/utils';
import useMobxStores from 'hooks/useMobxStores';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import styles from './index.module.less';
import captchaPng from './captcha.png';

const FormItem = Form.Item;

const LoginView = function (props) {
  const { appStore } = useMobxStores();

  const navigate = useNavigate();

  const [logining, setLogining] = useState(false);

  const [captchaImg, setCaptchaImg] = useState(captchaPng);

  const doLogin = async function (values) {
    setLogining(true);
    try {
      const { data } = await request({
        url: '/auth/oauth/token',
        method: 'GET',
        params: {
          grant_type: 'password',
          scope: 'server',
          client_id: 'twinship',
          ...values,
        },
      });

      Cookie.set(config.cookie.authName, data.access_token);
      localStorage.setItem(config.localData.username, data.user_name);
      localStorage.setItem(config.localData.userid, data.user_id);
      localStorage.setItem(config.localData.avatar, data.user_avatar);
      localStorage.setItem(config.localData.customerId, data.customer_id || '');
      localStorage.setItem(config.localData.accessibleMenus, JSON.stringify(data.menuDTOS || []));
      localStorage.setItem(config.localData.accessibleBtns, JSON.stringify(data.permissions || []));
      localStorage.setItem(config.localData.roles, JSON.stringify(data.roleDTOS || []));
      appStore.tags = [];
      if (!data.menuDTOS?.length) {
        message.warn('您没有权限查看系统页面，请联系管理员配置权限后再登录');
        setLogining(false);
        return;
      }
      // navigate(getDefaultPath(), { replace: true });
      navigate(config.defaultRoutePath, { replace: true });
    } catch (e) {
    }
    setLogining(false);
  };

  const getCaptcha = async () => {
    try {
      const { data } = await request({
        url: '/auth/oauth/token',
      });
    } catch (e) {
    }
  };

  useEffect(() => {
    // getCaptcha();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <img src="https://yishi-greatvision-public.oss-cn-guangzhou.aliyuncs.com/login/login.png" className={styles.formLogo} alt="login" />
      </div>
      <Form className={styles.form} onFinish={doLogin}>
        <div style={{ fontWeight: 'bold', fontSize: '18px;' }}> 串兮 </div>
        <div style={{ fontWeight: 'bold', fontSize: '18px;' }}> TWINSHIP </div>
        <div className={styles.inputsWrapper}>
          <FormItem
            label=""
            name="username"
            rules={[{ required: true, message: '请输入用户名!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="请输入用户名"
            />
          </FormItem>
          <FormItem
            label=""
            name="password"
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="请输入密码"
            />
          </FormItem>
          <div className={styles.captchaWrapper}>
            <img src={captchaImg} alt="验证码" onClick={getCaptcha} />
            <FormItem name="captcha" rules={[{ required: false, message: '请输入验证码' }]}>
              <Input placeholder="请输入验证码" />
            </FormItem>
          </div>
          <Button type="primary" style={{ width: '100%' }} htmlType="submit" loading={logining}>登录</Button>
        </div>
      </Form>
      <div className={styles.bottom}>
        <span>串兮AI科技有限公司</span>
        <a target="_blank" href="https://beian.miit.gov.cn/" rel="noreferrer">备案号</a>
      </div>
    </div>
  );
};

export default inject('appStore')(LoginView);
