import React from 'react';

export default [

  {
    path: '/sys-mgnt/user-mgnt/user-list',
    component: React.lazy(() => import('pages/sys-mgnt/user-mgnt/user-list/user-list-view')),
  },

  {
    path: '/sys-mgnt/user-mgnt/user-add',
    component: React.lazy(() => import('pages/sys-mgnt/user-mgnt/user-add/user-add-view')),
  },

  {
    path: '/sys-mgnt/user-mgnt/user-assign-perm',
    component: React.lazy(() => import('pages/sys-mgnt/user-mgnt/user-assign-perm/user-assign-perm-view')),
  },

  {
    path: '/sys-mgnt/user-mgnt/user-detail',
    component: React.lazy(() => import('pages/sys-mgnt/user-mgnt/user-detail/user-detail-view')),
  },
  {
    path: '/sys-mgnt/role-mgnt/role-list',
    component: React.lazy(() => import('pages/sys-mgnt/role-mgnt/role-list/role-list-view')),
  },
  {
    path: '/sys-mgnt/role-mgnt/role-add',
    component: React.lazy(() => import('pages/sys-mgnt/role-mgnt/role-add/role-add-view')),
  },
  {
    path: '/sys-mgnt/role-mgnt/role-edit',
    component: React.lazy(() => import('pages/sys-mgnt/role-mgnt/role-add/role-add-view')),
  },
  {
    path: '/sys-mgnt/role-mgnt/role-detail',
    component: React.lazy(() => import('pages/sys-mgnt/role-mgnt/role-detail/role-detail-view')),
  },
  {
    path: '/sys-mgnt/menu-mgnt',
    component: React.lazy(() => import('pages/sys-mgnt/menu-mgnt/menu-mgnt-view')),
  },
  {
    path: '/sys-mgnt/log-mgnt/log-list',
    component: React.lazy(() => import('pages/sys-mgnt/log-mgnt/log-list/log-list-view')),
  },
  {
    path: '/sys-mgnt/feedback-type',
    component: React.lazy(() => import('pages/sys-mgnt/feedback-type/feedback-type-view')),
  },
  {
    path: '/sys-mgnt/feedback-list',
    component: React.lazy(() => import('pages/sys-mgnt/feedback-list/feedback-list-view')),
  },
  {
    path: '/sys-mgnt/invite-code-list',
    component: React.lazy(() => import('pages/sys-mgnt/invite-code/invite-code-view')),
  },
];
