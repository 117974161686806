import { makeAutoObservable, observable } from 'mobx';
import {
  getChannelTotal, getItemUserList, getQuestionDetail, getQuestionFeedbackTotal, getQuestionItemFeedbackTotal, getQuestionItemTotal, getQuestionResultTotal, getQuestionTotal, getRefreshTime, getUserList,
} from './questionnaire-statistics-serv';

class Store {
  questionDetail = null

  questionTotalDetail = null

  questionChannelList = []

  questionUserList = []

  questionItemUserList = []

  questionFeedbackList = []

  questionItemFeedbackList = []

  questionItemTotalList = []

  questionResultDetail = null

  constructor() {
    makeAutoObservable(this, {
      questionDetail: observable.shallow,
      questionTotalDetail: observable.shallow,
      questionChannelList: observable.shallow,
      questionUserList: observable.shallow,
      questionItemUserList: observable.shallow,
      questionFeedbackList: observable.shallow,
      questionResultDetail: observable.shallow,
      questionItemTotalList: observable.shallow,
      questionItemFeedbackList: observable.shallow,
    });
  }

  async getRefreshTime(params) {
    const { data } = await getRefreshTime(params);
    return data;
  }

  async getQuestionDetail(params) {
    const { data } = await getQuestionDetail(params);
    this.questionDetail = data;
  }

  async getQuestionTotal(params) {
    const { data } = await getQuestionTotal(params);
    this.questionTotalDetail = data?.[0];
  }

  async getChannelTotal(params) {
    const { data } = await getChannelTotal(params);

    this.questionChannelList = data;
  }

  async getUserList(params) {
    const { data } = await getUserList(params);

    this.questionUserList = data?.records || [];
  }

  async getResultUserList(params) {
    const { data } = await getUserList(params);

    this.questionItemUserList = data?.records || [];
  }

  async getItemUserList(params) {
    const { data } = await getItemUserList(params);

    this.questionItemUserList = data?.records || [];
  }

  async getQuestionResultTotal(params) {
    const { data } = await getQuestionResultTotal(params);

    this.questionResultDetail = data[0];
  }

  async getQuestionItemTotal(params) {
    const { data } = await getQuestionItemTotal(params);

    this.questionItemTotalList = data;
  }

  async getQuestionItemFeedbackTotal(params) {
    const { data } = await getQuestionItemFeedbackTotal(params);

    this.questionItemFeedbackList = data.map((item) => {
      const { feedBackCountMap } = item;

      return {
        ...item,
        feedback1: feedBackCountMap?.[0] || 0,
        feedback2: feedBackCountMap?.[1] || 0,
        feedback3: feedBackCountMap?.[2] || 0,
        feedback4: feedBackCountMap?.[3] || 0,
      };
    });
  }

  async getQuestionFeedbackTotal(params) {
    const { data } = await getQuestionFeedbackTotal(params);

    // feedBackCountMap 反馈类型(0:没有,1:很少,2:一般,3:一些,4:很多)

    this.questionFeedbackList = data?.map((item) => {
      const {
        submitCount, feedBackCount, feedBackCountMap, submitPeopleCount, feedBackPeopleCount, questionResultName,
      } = item;

      return {
        questionResultName,
        submitCount: submitCount || 0,
        feedBackCount: feedBackCount || 0,
        submitPeopleCount: submitPeopleCount || 0,
        feedBackPeopleCount: feedBackPeopleCount || 0,
        none: feedBackCountMap?.[0] || 0,
        less: feedBackCountMap?.[1] || 0,
        normal: feedBackCountMap?.[2] || 0,
        better: feedBackCountMap?.[3] || 0,
        most: feedBackCountMap?.[4] || 0,
      };
    }) || [];
  }
}
export default new Store();
