const config = {
  // eslint-disable-next-line no-undef
  env: ENV,
  noTab: '',
  appName: '远见科普投放平台',
  cookie: {
    authName: 'auth',
    tokenId: 'tokenId',
  },
  localData: { // 存到localStorage里面的数据
    username: 'username',
    userid: 'userid',
    accessibleMenus: 'accessibleMenus',
    accessibleBtns: 'accessibleBtns',
    tokenId: 'tokenId',
    avatar: 'userAvatar',
    customerId: 'customerId', // 外部品牌客户id
    roles: 'roles',
  },
  defaultRoutePath: '/home-mgnt', // 内容用户登录后默认页面
  brandCustomerDefaultPath: '/analysis-mgnt/business-data-report', // 外部品牌用户登录后默认跳转到合作数据报表
  // eslint-disable-next-line no-undef
  isStaticMenu: true, // ['loc', 'dev', 'test'].includes(ENV) && !!sessionStorage.getItem('use-static-menu'),
  isLogin: false, // 是否校验登录
  // eslint-disable-next-line no-undef
  isCheckPermission: ['loc', 'dev', 'test'].includes(ENV) ? !sessionStorage.getItem('not-check-permission') : true,
  apiDocsUrl: '/api-docs',
  ossTokenApi: '/yinweifuli-system-web/file/get-token',
};

const envConfig = {
  loc: {
    mock: false,
    publicPath: '',
    host: '/',
    bucketName: 'twinship-test',
  },
  dev: {
    mock: false,
    publicPath: '',
    host: 'http://admin-test.twinship.cn',
    bucketName: 'twinship-test',
  },
  test: {
    mock: false,
    publicPath: '',
    host: 'http://admin-test.twinship.cn/',
    bucketName: 'twinship-test',
  },
  uat: {
    mock: false,
    publicPath: '',
    host: 'http://admin-test.twinship.cn/',
    bucketName: 'twinship-test',
  },
  prod: {
    mock: false,
    publicPath: '',
    host: 'http://admin.twinship.cn/',
    bucketName: 'twinship-test',
  },
};

// 合并配置
Object.assign(config, envConfig[config.env]);

const getRootPath = () => {
  const curPath = window.document.location.href;
  const pathName = window.document.location.pathname;
  const pos = curPath.indexOf(pathName);
  // 获取主机地址，如： http://localhost:8083
  const locPath = curPath.substring(0, pos);
  return locPath;
};

const localMenuAndRoute = (url) => {
  const arr = ['menu/list', 'router/list'];
  if (arr.indexOf(url) !== -1) {
    return true;
  }
  return false;
};

const getApiAppName = function (url) {
  if (!url) {
    return '';
  } if (url.indexOf('http') >= 0) {
    return url;
  }
  // 本机开发环境，则当前assets/mock下面的json
  if (config.mock || localMenuAndRoute(url)) {
    return `${getRootPath() + config.publicPath}/assets/mock/${url.replace(/\//g, '-')}.json`;
    // 其它环境，则读取真实应用的接口
  }
  const version = '';
  const { host } = config;

  if (url.includes('parttime-system-web')) {
    // url = `system-web/${url}`;
  } else if (url.includes('yinweifuli-shop-web')) {
    url = `shop-web/${url}`;
  } else if (url.includes('yinweifuli-opc-web')) {
    url = `opc-web/${url}`;
  } else if (url.includes('yinweifuli-order-web')) {
    url = `order-web/${url}`;
  } else if (url.includes('yinweifuli-user-web')) {
    url = `user-web/${url}`;
  } else if (url.includes('yinweifuli-marketing-web')) {
    url = `marketing-web/${url}`;
  } else if (url.includes('greatvision-information-admin')) {
    url = `information-admin/${url}`;
  } else if (url.includes('greatvision-order-admin')) {
    url = `order-admin/${url}`;
  } else if (url.includes('greatvision-aigc-admin')) {
    url = `aigc-admin/${url}`;
  } else if (url.includes('greatvision-system-admin')) {
    url = `system-admin/${url}`;
  }

  let apiPath = `${version}/${url}`;
  apiPath = apiPath.replace(/\/{2,}/g, '/');
  apiPath = apiPath.replace(/^\//, '');

  return `${host.replace(/\/$/, '')}/${apiPath}`;
};

const isAsMicroApp = () => window.__POWERED_BY_QIANKUN__;

// 拼接接口所需域名和服务名，只需要输入接口名即可  如 yundt/mgmt/item/list-by-page，也不要加斜杆开始，
// 如果接口以http开头，则不会进拼接，而是保留原样
// 如果是mock，则会去assets/mock请求同名json，但/会被替换为-   如  yundt-mgmt-item-list-by-page.js
config.apiAppName = getApiAppName;
config.isAsMicroApp = isAsMicroApp;

export default config;
