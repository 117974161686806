import { makeAutoObservable, observable } from 'mobx';
import moment from 'moment';
import { getDetail, getBrandList, getShopList } from './commodity-add-serv';

class Store {
  values = {
    effectiveFlag: 1, // 默认永久有效
  }

  brandList = []

  specCols = [] // 所有属性

  skus = []

  paramsList = []

  merchantList = []

  shopList = []

  backTree = []

  showAddCommodityNameInput = false

  initState() {
    this.values = {
      effectiveFlag: 1,
    };
    this.specCols = [];
    this.skus = [];
    this.shopList = [];
    this.paramsList = [];
    this.showAddCommodityNameInput = false;
  }

  constructor() {
    makeAutoObservable(this, {
      brandList: observable.shallow,
      values: observable.shallow,
      merchantList: observable.shallow,
      shopList: observable.shallow,
      backTree: observable.shallow,
    });
  }

  async getDetail(id) {
    try {
      const { data } = await getDetail(id);
      if (data.effectiveFlag === 2 && data.effectiveStartTime && data.effectiveEndTime) {
        data.effectiveTime = [moment(data.effectiveStartTime), moment(data.effectiveEndTime)];
      }
      if (data.commodityImageDTOS?.length) {
        let hasMain = false; // 是否有主图
        data.picFiles = {
          fileList: data.commodityImageDTOS.map((item) => {
            let name = '';
            if (item.fileUrl) {
              const lastIdx = item.fileUrl.lastIndexOf('_');
              name = item.fileUrl.substring(lastIdx + 1);
            }
            if (`${item.imageType}` === '0') {
              hasMain = true;
            }
            return {
              ...item, url: item.fileUrl, type: item.fileType, name: item.name || name,
            };
          }),
        };
        if (!hasMain) {
          data.picFiles.fileList[0].imageType = 0;
        }
      }
      const specs = {};
      const paramsList = [];
      data?.commoditySkuDTOS?.forEach((skuItem, idx) => {
        if (skuItem.specs) {
          try {
            skuItem.specs = JSON.parse(skuItem.specs);
            Object.assign(specs, skuItem.specs);
          } catch (e) {
          }
        }
        if (skuItem.fileUrl) {
          skuItem.skuImageFile = {
            fileList: [{ fileId: skuItem.fileId, url: skuItem.fileUrl }],
          };
        }
      });
      data?.commodityParameterDTOS?.forEach((param) => {
        paramsList.push(param.parameterKey);
      });
      this.specCols = Object.keys(specs).map((item) => ({ dataIndex: item }));
      this.skus = data?.commoditySkuDTOS || [];
      this.paramsList = paramsList;
      data.specCols = this.specCols;
      data.skus = data?.commoditySkuDTOS;
      data.parameters = data?.commodityParameterDTOS || [];
      this.values = data;
    } catch (e) {
    }
  }

  async getBrandList() {
    const { data } = await getBrandList({ current: 1, size: 200, enableFlags: [1] });
    this.brandList = data || [];
  }

  async getShopList() {
    const { data } = await getShopList({});
    this.shopList = data?.records || [];
  }
}
export default new Store();
