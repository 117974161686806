import { makeAutoObservable } from 'mobx';

class Store {
  biz = {}

  constructor() {
    makeAutoObservable(this);
  }
}
export default new Store();
