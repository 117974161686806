import request from 'utils/request';

export function updateEnableFlag(params: {ids: string[], enableFlag: number}) {
  return request({
    url: '/yinweifuli-system-web/role/update-enable-flag',
    method: 'PUT',
    data: params,
  });
}
export function getRoleList(params = {}) {
  return request({
    url: '/yinweifuli-system-web/role/list',
    params: { current: 1, size: 1000, ...params },
  });
}
