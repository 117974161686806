import request from 'utils/request';

export function getChannelList() {
  return request({
    url: '/parttime-system-web/channel/list',
  });
}

export function getDistributeMatchNum(params) {
  return request({
    url: '/parttime-system-web/match-log/calculate-num',
    method: 'POST',
    data: params,
  });
}
