import request from 'utils/request';

export function getAsyncData(params) {
  return request({
    url: 'xxx',
    params,
  });
}

// 获取名字
export function getName(params) {
  return request({
    url: '/yinweifuli-shop-web/brand/list',
    method: 'GET',
    params,
  });
}
