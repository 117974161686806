import React from 'react';

export default [

  {
    path: '/customer-mgnt/customer-list',
    component: React.lazy(() => import('pages/customer-mgnt/customer-list/customer-list-view')),
  },

  {
    path: '/customer-mgnt/customer-detail',
    component: React.lazy(() => import('pages/customer-mgnt/customer-detail/customer-detail-view')),
  },
  // --APPEND-ROUTER
];
