import { makeAutoObservable, observable } from 'mobx';
import {
  endUpQuestion, getQuestionChannelList, releaseQuestion, startOrPauseQuestion,
} from './questionnaire-list-serv';

class Store {
  questionChannelList = []

  constructor() {
    makeAutoObservable(this, {
      questionChannelList: observable.shallow,
    });
  }

  async startOrPauseQuestion(params) {
    await startOrPauseQuestion(params);
  }

  async endUpQuestion(params) {
    await endUpQuestion(params);
  }

  async releaseQuestion(params) {
    await releaseQuestion(params);
  }

  async getQuestionChannelList(params) {
    const { data } = await getQuestionChannelList(params);
    this.questionChannelList = data;
  }
}
export default new Store();
