import { makeAutoObservable, observable } from 'mobx';
import { getLogList } from './log-list-serv';

class Store {
  biz = {} // list page组件提供的属性及方法的对象

  logList = [] // 关联专家列表

  constructor() {
    makeAutoObservable(this, {
      biz: observable.shallow,
      logList: observable.shallow,
    });
  }

  async getLogList(params) {
    try {
      const { data } = await getLogList(params);
      this.logList = data;
    } catch (e) {
    }
  }
}
export default new Store();
