import request from 'utils/request';

export function getQuestionDetail(questionId) {
  return request({
    url: `/yinweifuli-marketing-web/question/get-detail-by-id/${questionId}`,
    method: 'GET',
    needLoading: true,
  });
}

export function getQuestionTotal(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data/total',
    method: 'GET',
    params,
  });
}

export function getChannelTotal(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data/channel-total',
    method: 'GET',
    params,
  });
}

export function getUserList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data/user-find-page',
    method: 'GET',
    params,
  });
}

export function getItemUserList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data-item/user-find-page',
    method: 'GET',
    params,
  });
}

export function getQuestionFeedbackTotal(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data/question-feedback-total',
    method: 'GET',
    params,
  });
}

export function getQuestionResultTotal(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data/result-total',
    method: 'GET',
    params,
  });
}

export function getQuestionItemTotal(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data-item/question-item-total',
    method: 'GET',
    params,
  });
}

export function getQuestionItemFeedbackTotal(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data-item/question-item-feedback-total',
    method: 'GET',
    params,
  });
}

export function getRefreshTime(questionId) {
  return request({
    url: `/yinweifuli-marketing-web/question-submit-data/data-refresh-time/${questionId}`,
    method: 'GET',
  });
}
