import React from 'react';

export default [

  {
    path: '/order-mgnt/order-list',
    component: React.lazy(() => import('pages/order-mgnt/order-list/order-list-view')),
  },

  {
    path: '/order-mgnt/order-detail',
    component: React.lazy(() => import('pages/order-mgnt/order-detail/order-detail-view')),
  },
  // --APPEND-ROUTER
];
