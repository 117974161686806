/*
  antd的modal
*/

import React from 'react';
import { Modal, ModalProps } from 'antd';

interface SelfProps {
  children: React.ReactElement | React.ReactElement[]
}

type Props = ModalProps & SelfProps;

export default function XModal(props: Props) {
  const {
    children, ...modalProps
  } = props;

  return (
    <Modal
      centered // 垂直居中展示
      maskClosable={false} // 点击遮罩层不关闭
      {...modalProps}
    >
      {children}
    </Modal>
  );
}

XModal.defaultProps = {
};
