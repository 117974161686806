import React, { useRef, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { Divider, Popover, Tag } from 'antd';
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDefaultPath } from 'utils/utils';
import classnames from 'classnames';
import useMobxStores from 'hooks/useMobxStores';
import styles from './index.module.less';

function PageTabsView(props) {
  const { className } = props;
  const { appStore } = useMobxStores();

  const navigate = useNavigate();
  const location = useLocation();
  const tagsWrapperRef = useRef(null);

  useEffect(() => {
    if (location.pathname !== '/') { // 首页'/'，会被重定向到工作台或合作数据报表页面, 所以不用添加到tabs
      addTag(location);
    }
    const { pathname, search } = location;
    addTag({ pathname, search });
  }, [location]);

  useEffect(() => {
    const tagsWrapper = tagsWrapperRef.current;
    tagsWrapper?.querySelector('[class*="active"]')?.scrollIntoView?.({ behavior: 'smooth' });
  }, [appStore.tags]);

  const addTag = ({ pathname, search }) => {
    const tags = cloneDeep(appStore.tags);
    const idx = tags.findIndex((tag) => tag.pathname === pathname);
    if (idx === -1) { // not exist
      tags.push({
        pathname,
        search,
      });
    } else { // update
      tags[idx] = { pathname, search };
    }
    appStore.tags = tags;
  };

  const removeTag = (tag) => {
    const nextActiveTag = appStore.closeTab(tag.pathname);
    navigate(nextActiveTag || getDefaultPath());
  };

  const handleCloseAll = () => {
    appStore.tags = [];
    navigate(getDefaultPath());
  };

  const scrollTags = (direction) => {
    const tagsWrapper = tagsWrapperRef.current;
    const offset = (tagsWrapper?.scrollLeft || 0) + (direction === 'right' ? 100 : -100);
    tagsWrapper?.scroll({ left: offset, behavior: 'smooth' });
  };

  return (
    <div className={classnames(styles.page_tab, className)}>
      <div className={styles.tags_wrapper} ref={tagsWrapperRef}>
        {
          appStore.tags.map((tag, index) => (
            <Tag
              key={index}
              className={location.pathname === tag.pathname ? styles.active : ''}
              onClick={() => navigate(tag, { replace: true })}
            >
              {{ ...appStore.path2MenuLabel }[tag.pathname]}

              <CloseOutlined
                onClick={
                  (evt) => {
                    evt.stopPropagation();
                    removeTag(tag);
                  }
                }
              />
            </Tag>
          ))
        }
      </div>
      <div className={styles.actions_wrapper}>
        <Divider type="vertical" />
        <Popover content="关闭所有">
          <CloseOutlined onClick={handleCloseAll} />
        </Popover>
        <LeftOutlined onClick={() => scrollTags('left')} />
        <RightOutlined onClick={() => scrollTags('right')} />
      </div>
    </div>
  );
}

export default observer(PageTabsView);
