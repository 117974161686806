import { makeAutoObservable, observable } from 'mobx';
import { getDetail } from './commodity-detail-serv';

class Store {
  detail = {}

  specCols = []

  constructor() {
    makeAutoObservable(this, {
      specCols: observable.shallow,
    });
  }

  async getDetail(id) {
    try {
      const { data } = await getDetail(id);
      const specs = {};
      const paramsList = [];
      data?.commoditySkuDTOS?.forEach((skuItem, idx) => {
        if (skuItem.specs) {
          try {
            skuItem.specs = JSON.parse(skuItem.specs);
            Object.assign(skuItem, skuItem.specs);
            Object.assign(specs, skuItem.specs);
          } catch (e) {
          }
        }
      });
      data?.commodityParameterDTOS?.forEach((param) => {
        paramsList.push(param.parameterKey);
      });
      this.specCols = Object.keys(specs).map((item) => ({ dataIndex: item, title: item }));
      this.detail = data || {};
    } catch (e) {
    }
  }
}
export default new Store();
