import {
  DownOutlined, FormOutlined, BellOutlined, EditOutlined,
} from '@ant-design/icons';
import OSS from 'ali-oss';
import {
  Badge, Button, Dropdown, Form, Image, Layout, Menu, message, Result, Steps, Divider,
} from 'antd';
import XModal from 'components/xmodal';
import config from 'config/config';
import Cookie from 'js-cookie';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import request from 'utils/request';
import useMobxStores from 'hooks/useMobxStores';
import styles from './index.module.less';
import returnIcon from './return-icon.png';

const { Header } = Layout;
const MenuItem = Menu.Item;
const { Step } = Steps;

function HeaderView(props) {
  const { appStore } = useMobxStores();

  const username = localStorage.getItem(config.localData.username);
  const avatar = localStorage.getItem(config.localData.avatar);
  const userId = localStorage.getItem(config.localData.userid);
  const newVersion = document.documentElement.dataset.version;
  const oldVersion = localStorage.getItem(`version${userId}`);

  const [passModalVisible, setPassModalVisible] = useState(false);
  const [releaseVisible, setReleaseVisible] = useState(false);

  const [current, setCurrent] = useState(0);
  const [resetPwdNum, setResetPwdNum] = useState(-1);

  const [errorText, setErrorText] = useState('');
  const [total, setTotal] = useState(0);

  const [formOne] = Form.useForm();
  const [formTwo] = Form.useForm();

  const [userDetail, setUserDetail] = useState({ username, avatar });
  const [mindMappingUrl, setMidMappingUrl] = useState(`//${config.bucketName}.oss-cn-guangzhou.aliyuncs.com/release/${newVersion}.jpg`);
  const [uploadLoading, setUploadLoading] = useState(false);

  const navigate = useNavigate();

  let abbr = '';
  if (/^[A-Za-z]+$/.test(username)) { // 英文名子
    abbr = username?.substring?.(0, 2);
  } else { // 中文名子
    abbr = username?.slice?.(-2);
  }

  useEffect(() => {
    if (newVersion !== oldVersion) {
      localStorage.setItem(`version${userId}`, newVersion);
      // setReleaseVisible(true);
    }
  }, []);

  useEffect(() => {
    // request({
    //   url: '/greatvision-information-admin/current-user/count',
    //   method: 'GET',
    // }).then((res) => {
    //   setTotal(res.data.stationLetterCount);
    // });

    const asyncFetch = async () => {
      try {
        const { data } = await request({
          url: '/yinweifuli-system-web/current-user/detail',
        });
        if (data.resetPwdNum === 0) {
          setPassModalVisible(true);
          setResetPwdNum(0);
        } else {
          setUserDetail({ ...data, avatar: data.avatar || userDetail.avatar });
          appStore.curUser = data;
        }
      } catch (e) {
      }
    };
    // asyncFetch();
  }, []);

  const handleLogout = async function () {
    try {
      request({
        url: '/auth/oauth/logout',
        method: 'DELETE',
      });
      Cookie.remove(config.cookie.authName);
      localStorage.removeItem(config.localData.accessibleMenus);
      navigate('/login', { replace: true });
      location.reload(); // 清除store里面的数据
    } catch (e) {
    }
  };

  const uploadMindMapping = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png,.jpg,.jpeg';
    input.onchange = async function (evt) {
      setUploadLoading(true);
      const file = evt.target.files[0];
      const { data } = await request({ url: `/yinweifuli-system-web/oss/get-sts-token?bucket=${config.bucketName}` });
      const filePath = `/release/${newVersion}.jpg`;
      const client = OSS(data);
      try {
        const result = await client.put(
          filePath,
          file,
          {
            callback: {
              url: data.callbackUrl,
              body: data.callbackBody,
            },
          },
        );
        setMidMappingUrl(`${result.url}?${Date.now()}`);
        message.success('思维导图上传成功');
      } catch (error) {
      }
      setUploadLoading(false);
    };
    input.click();
  };

  return (
    <Header className={styles.header}>
      <div className={styles.left}>
        {/* { */}
        {/*  hasWorkBenchPerm && ( */}
        {/*    <Action */}
        {/*      className={styles.action} */}
        {/*      onClick={() => handleJumpUrl('/workbench-mgnt/personal-workbench')} */}
        {/*    > */}
        {/*      {history.location.pathname === '/workbench-mgnt/personal-workbench' ? '我的工作台' : ( */}
        {/*        <> */}
        {/*          返回工作台 */}
        {/*          <img src={returnIcon} /> */}
        {/*        </> */}
        {/*      )} */}
        {/*    </Action> */}
        {/*  ) */}
        {/* } */}
      </div>

      <div className={styles.right}>
        <a href="" target="_blank" rel="noreferrer">
          <span className={styles.advice} title="提意见"><EditOutlined /></span>
        </a>
        <Divider type="vertical" />
        {/* {!isCustomer && <div className={styles.newVersionTip} onClick={() => setReleaseVisible(true)}>新功能</div>} */}
        {/* <Dropdown */}
        {/*  overlay={( */}
        {/*    <Menu> */}
        {/*      <MenuItem */}
        {/*        key="1" */}
        {/*        onClick={() => navigate('/message-mgnt/my-message-list?readFlags=0')} */}
        {/*      > */}
        {/*        <div> */}
        {/*          <span>站内信</span> */}
        {/*          <span> */}
        {/*            （ */}
        {/*            <span className={styles.redFont}>{total}</span> */}
        {/*            ） */}
        {/*          </span> */}
        {/*        </div> */}
        {/*      </MenuItem> */}
        {/*    </Menu> */}
        {/*  )} */}
        {/* > */}
        <Badge
          overflowCount={99}
          count={total}
          className={styles.message}
        >
          <BellOutlined />
        </Badge>
        {/* </Dropdown> */}

        <Dropdown
          className={styles.dropdown}
          overlay={(
            <Menu>
              <MenuItem key="2" onClick={handleLogout}>
                注销
              </MenuItem>
            </Menu>
          )}
        >
          <div className={styles.user}>
            <div
              className={styles.avatarWrapper}
              // onClick={handleUploadAvatar}
            >
              {userDetail.avatar ? (
                <div
                  className={styles.avatar}
                  style={{ backgroundImage: `url(${userDetail.avatar})` }}
                />
              ) : (
                <div
                  className={`${styles.avatar} ${styles.abbr}`}
                  style={{ backgroundImage: 'linear-gradient(to top, #1882F0FF, #1882F0FF)' }}
                >
                  {abbr}
                </div>
              )}
              <div
                className={styles.avatarEditIconWrapper}
                style={{ display: 'none' }}
              >
                <FormOutlined />
              </div>
            </div>
            <span>{username}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>

      <XModal
        zIndex={1001}
        title={<div style={{ fontWeight: 'bold' }}>系统新功能</div>}
        destroyOnClose
        open={releaseVisible}
        width={450}
        footer={(
          <>
            {userId === '1' && <Button type="primary" loading={uploadLoading} onClick={uploadMindMapping}>上传思维导图</Button>}
            <Button type="primary" onClick={() => setReleaseVisible(false)}>确定</Button>
          </>
        )}
        onCancel={() => setReleaseVisible(false)}
      >
        <Image src={mindMappingUrl} style={{ maxWidth: '450px' }} />
        <div className={styles.linkBox}>
          详情请戳这：
          {/* <span className={styles.link} onClick={() => window.open('https://zjzhyla6o6.feishu.cn/docs/doccnlOgTDTnUg9pPJn3XCbJOGg', '_blank')}>系统发版内容通知</span> */}
        </div>
      </XModal>
    </Header>
  );
}

export default HeaderView;
