import { makeAutoObservable } from 'mobx';

class Store {
    biz = {}

    constructor() {
      makeAutoObservable(this, {
        biz: false,
      });
    }
}

export default new Store();
