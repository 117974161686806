import request from 'utils/request';

export function getDetail(id) {
  return request({
    url: `/yinweifuli-marketing-web/coupon-activity/get-detail-by-id/${id}`,
    method: 'GET',
  });
}

export function getAllMerchants(params = { current: 1, size: 1000 }) {
  return request({
    url: '/yinweifuli-shop-web/merchant/list',
    method: 'GET',
    params,
    needLoading: true,
  });
}
