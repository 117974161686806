import request from 'utils/request';

export function getDetail(id) {
  return request({
    url: `/yinweifuli-order-web/order-after-sales/get-detail-by-id/${id}`,
    data: { id },
  });
}

export function getOrderDetail(id) {
  return request({
    url: `/yinweifuli-order-web/order/get-detail-by-id/${id}`,
    method: 'GET',
  });
}

export function getLogisticList(params) {
  return request({
    url: '/yinweifuli-order-web/order-after-sales-logistics/list',
    method: 'GET',
    params,
  });
}

export function getAftersaleOrderItems(params) {
  return request({
    url: '/yinweifuli-order-web/order-after-sales-item/list',
    params,
  });
}

export function getLogList(params) {
  return request({
    url: '/yinweifuli-order-web/order-after-sales-event-log/list',
    params,
  });
}
