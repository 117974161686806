import request from 'utils/request';

export function getDetail(id) {
  return request({
    url: '',
    method: 'POST',
    data: { id },
  });
}

export function getRoleListOfUser(userId?:string) {
  return request({
    url: '/yinweifuli-system-web/role/list',
    method: 'GET',
    params: { userId },
  });
}

export function getExcludeBusinessAccountList(params) {
  return request({
    url: '/yinweifuli-system-web/business-account/list',
    method: 'GET',
    params,
  });
}

export function createBusinessAccount(params) {
  return request({
    url: '/yinweifuli-system-web/user-business-account-ref/create',
    method: 'POST',
    data: params,
  });
}

export function updateEnableFlag(params) {
  return request({
    url: '/yinweifuli-system-web/user-business-account-ref/update',
    method: 'PUT',
    data: params,
  });
}
