import request from 'utils/request';

export function getAnswerTabList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data/list',
    method: 'GET',
    params,
  });
}

export function getAnswerDetail(questionSubmitDataId) {
  return request({
    url: `/yinweifuli-marketing-web/question-submit-data/get-detail-by-id/${questionSubmitDataId}`,
    method: 'GET',
  });
}

export function getRemarkList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data-remark/list',
    method: 'GET',
    params,
  });
}

export function createRemark(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-submit-data-remark/create',
    method: 'POST',
    data: params,
  });
}
