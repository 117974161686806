import { makeAutoObservable, observable } from 'mobx';
import * as Serv from './role-add-serv';

class Store {
  values = {
    enableFlag: 1,
  }

  biz = {}

  refMenuKeys = null

  menuTree = null // 菜单树

  menuMap = {}

  chosenBtnMap = {}

  constructor() {
    makeAutoObservable(this, {
      values: observable.shallow,
      refMenuKeys: observable.shallow,
      menuTree: observable.shallow,
      menuMap: observable.shallow,
      chosenBtnMap: observable.shallow,
      biz: observable.shallow,
    });
  }

  initState() {
    this.values = {
      enableFlag: 1,
    };
    this.refMenuKeys = null;
    this.chosenBtnMap = {};
  }

  async getMenuTree() {
    try {
      const { code, data } = await Serv.getMenuTree();
      if (`${code}` !== '0') {
        return;
      }
      const menuMap = {};
      const recursiveArr = (arr) => {
        arr.forEach((item) => {
          if (`${item.type}` === '0') {
            menuMap[item.menuId] = item;
          }
          if (Array.isArray(item.childrens)) {
            item.childrens = recursiveArr(item.childrens);
          }
        });
        return arr.filter((item) => item.isTreeShow);
      };

      this.menuTree = recursiveArr(data || []);
      this.menuMap = menuMap;
    } catch (e) {
    }
  }

  async getDetail(id: string) {
    try {
      const { data } = await Serv.getDetail(id);
      this.values = data;
    } catch (e) {
    }
  }

  async getRefMenus(roleId: string) { // 角色关联的菜单
    try {
      const { data } = await Serv.getRefList(roleId, 0);
      const refSet = new Set(data.map((item) => item.menuId));
      const removeRefKey = () => {
        // 处理设置权限后又添加了新的菜单或按钮的场景，假设有菜单项 [{'专家合同(id:1)'， ，{children: ['分页(id:2)']}}]，
        // 把这两个权限全部勾选上保存，则refList为[1, 2]，如果新增一个按钮'编辑(id:3)'， 菜单项变为[{'专家合同(id:1)'， ，{children: ['分页(id:2)', 编辑(id:3)]}}]
        // 这时候要把 1 从refList中删除，如果不删除，则在antd的Tree组件中{'专家合同(id:1)被勾选上，由于这个父级节点被勾选，新增的'编辑'按钮也会被勾选上
        const menuList = [];
        const recursiveTree = (menu, parentLevel) => { // parentLevel: 菜单层级,
          menu?.forEach?.((menuItem) => {
            menuItem.level = parentLevel + 1;
            menuList.push(menuItem);
            Array.isArray(menuItem.childrens) && recursiveTree(menuItem.childrens, parentLevel + 1);
          });
        };
        recursiveTree(this.menuTree, 0);
        menuList.sort((a, b) => b.level - a.level); // 从最底层级往高层级开始遍历
        menuList.forEach((menuItem) => {
          if (menuItem.childrens?.length) {
            menuItem.childrens.forEach((child) => {
              if (!refSet.has(child.menuId)) { // 新增了新的菜单或按钮
                refSet.delete(menuItem.menuId); // 没新增菜单或按钮前是全选状态，新增后，不能再是全选状态，把该menuId删除
              }
            });
          }
        });
      };
      removeRefKey();
      this.refMenuKeys = Array.from(refSet);
    } catch (e) {
    }
  }

  async getRefBtns(roleId: string) { // 角色关联的按钮
    const { data } = await Serv.getRefList(roleId, 1);
    const refBtns = data || [];
    const obj = {};
    refBtns.forEach((b) => {
      obj[b.menuId] = b;
    });
    this.chosenBtnMap = obj;
  }
}
export default new Store();
