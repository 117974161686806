export default [
  {
    label: '首页',
    icon: '',
    routePath: '/home-mgnt',
    hidden: true,
    value: 0,
  },
  {
    label: '业务配置',
    icon: 'iconfont icon-xitongguanli',
    value: 'ywpz',
    children: [
      {
        label: '匹配规则',
        routePath: '/biz-config/match-rule',
        value: 'ywpz-0.1',
      },
    ],
  },
  {
    label: '系统管理',
    hidden: true,
    icon: 'iconfont icon-xitongguanli',
    value: 1,
    children: [
      {
        label: '反馈列表',
        routePath: '/sys-mgnt/feedback-list',
        value: '1-0.2',
      },
      {
        label: '邀请码',
        routePath: '/sys-mgnt/invite-code-list',
        value: '1-0.3',
      },
      {
        label: '用户管理',
        routePath: '/sys-mgnt/user-mgnt/user-list',
        value: '1-0',
        hidden: true,
        children: [
          {
            label: '新增用户',
            hidden: true,
            btnCode: 'user:add',
            value: '1-0-0',
          },
          {
            label: '导入',
            hidden: true,
            btnCode: 'user:import',
            value: '1-0-1',
          },
          {
            label: '查看',
            hidden: true,
            btnCode: 'user:detail',
            value: '1-0-2',
          },
          {
            label: '编辑',
            hidden: true,
            btnCode: 'user:edit',
            value: '1-0-3',
          },
          {
            label: '授权',
            hidden: true,
            btnCode: 'user: authorization',
            value: '1-0-4',
          },
          {
            label: '设置有效期',
            hidden: true,
            btnCode: 'user:set-expire-time',
            value: '1-0-5',
          },
        ],
      },
      {
        label: '新增用户',
        routePath: '/sys-mgnt/user-mgnt/user-add',
        value: '1-1',
        hidden: true,
      },
      {
        label: '用户授权',
        routePath: '/sys-mgnt/user-mgnt/user-assign-perm',
        value: '1-2',
        hidden: true,
      },
      {
        label: '用户详情',
        routePath: '/sys-mgnt/user-mgnt/user-detail',
        value: '1-3',
        hidden: true,
      },
      {
        label: '角色管理',
        routePath: '/sys-mgnt/role-mgnt/role-list',
        value: '1-4',
        hidden: true,
      },
      {
        label: '新增角色',
        routePath: '/sys-mgnt/role-mgnt/role-add',
        value: '1-5',
        hidden: true,
      },
      {
        label: '角色详情',
        routePath: '/sys-mgnt/role-mgnt/role-detail',
        value: '1-6',
        hidden: true,
      },
      {
        label: '菜单管理',
        routePath: '/sys-mgnt/menu-mgnt',
        value: '1-7',
        hidden: true,
      },
    ],
  },
  {
    label: '商户管理',
    icon: 'iconfont icon-zhuanjiaguanli',
    value: 3,
    children: [
      {
        label: '商户列表',
        routePath: '/merchant-mgnt/merchant-list',
        value: '3-0',
      },
      {
        label: '平台店铺',
        routePath: '/merchant-mgnt/shop-list',
        value: '3-1',
      },
    ],
  },
  {
    label: '用户管理',
    icon: 'UsergroupAddOutlined',
    value: 6,
    children: [
      {
        label: '用户列表',
        routePath: '/customer-mgnt/customer-list',
        value: '6-0',
      },
      {
        label: '用户360°视图',
        routePath: '/customer-mgnt/customer-detail',
        value: '6-1',
        hidden: true,
      },
    ],
  },
  {
    label: '营销管理',
    icon: '',
    value: 7,
    hidden: true,
    children: [
      {
        label: '优惠券管理',
        routePath: '/coupon-mgnt/coupon-batch-list',
        value: '7-0',
      },
      {
        label: '新增优惠券',
        routePath: '/coupon-mgnt/coupon-batch-add',
        value: '7-1',
        hidden: true,
      },
      {
        label: '优惠券领取管理',
        routePath: '/coupon-mgnt/coupon-receive',
        value: '7-2',
      },
    ],
  },
  {
    hidden: true,
    label: '优惠券详情',
    routePath: '/coupon-mgnt/coupon-detail',
    value: 8,
  },
  {
    label: '订单管理',
    value: 9,
    children: [
      {
        label: '订单列表',
        routePath: '/order-mgnt/order-list',
        value: '9-1',
      },
    ],
  },
];
