import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { getDetail } from './user-add-serv';

class Store {
  detail = {}

  values = {}

  userId = ''

  constructor() {
    makeAutoObservable(this);
  }

  initState() {
    this.values = {};
    this.userId = '';
  }

  async getDetail(id) {
    try {
      const { data } = await getDetail(id);
      if (data.validPeriodStartTime && data.validPeriodEndTime) {
        data.validPeriodStartEnd = [
          moment(data.validPeriodStartTime, 'YYYY-MM-DD HH:mm:ss'),
          moment(data.validPeriodEndTime, 'YYYY-MM-DD HH:mm:ss'),
        ];
      }
      this.values = data;
      this.userId = id;
    } catch (e) {
    }
  }
}
export default new Store();
