import request from 'utils/request';

export function getConditions(params) {
  return Promise.resolve({
    code: 0,
    msg: null,
    data: [
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 23:36:51',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-17 23:43:46',
        matchConditionId: '102',
        name: '固定规则-定位距离',
        matchConditionCategoryId: '0',
        type: 4,
        parentId: null,
        code: 'user_distance',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 23:36:04',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-17 23:43:46',
        matchConditionId: '101',
        name: '固定规则-提交接单时间',
        matchConditionCategoryId: '0',
        type: 4,
        parentId: null,
        code: 'user_apply_time',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 23:35:14',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-17 23:43:46',
        matchConditionId: '100',
        name: '固定规则-提交用工时间',
        matchConditionCategoryId: '0',
        type: 4,
        parentId: null,
        code: 'store_apply_time',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '18',
        name: '商户认证',
        matchConditionCategoryId: '3',
        type: 0,
        parentId: null,
        code: 'store_auth',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '9',
        name: '用户性格',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'user_nature',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '8',
        name: '用户身高',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'user_height',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '7',
        name: '用户年龄',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'user_age',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '6',
        name: '用户性别',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'user_gender',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '5',
        name: '工作次数',
        matchConditionCategoryId: '1',
        type: 1,
        parentId: null,
        code: 'work_count',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '4',
        name: '工作时段',
        matchConditionCategoryId: '1',
        type: 0,
        parentId: null,
        code: 'work_time',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '3',
        name: '工作日期',
        matchConditionCategoryId: '1',
        type: 0,
        parentId: null,
        code: 'work_date',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '21',
        name: '商户评分',
        matchConditionCategoryId: '3',
        type: 1,
        parentId: null,
        code: 'store_comment_score',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '20',
        name: '商户-违约记录',
        matchConditionCategoryId: '3',
        type: 1,
        parentId: null,
        code: 'store_default_count',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '2',
        name: '工作区域',
        matchConditionCategoryId: '1',
        type: 0,
        parentId: null,
        code: 'job_region',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '19',
        name: '商户-取消次数',
        matchConditionCategoryId: '3',
        type: 1,
        parentId: null,
        code: 'store_cancel_count',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '1',
        name: '工作类型',
        matchConditionCategoryId: '1',
        type: 0,
        parentId: null,
        code: 'job_type',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '17',
        name: '用户评分',
        matchConditionCategoryId: '2',
        type: 1,
        parentId: null,
        code: 'user_comment_score',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '16',
        name: '用户-违约记录',
        matchConditionCategoryId: '2',
        type: 1,
        parentId: null,
        code: 'user_default_count',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '15',
        name: '用户-取消次数',
        matchConditionCategoryId: '2',
        type: 1,
        parentId: null,
        code: 'user_cancel_count',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '14',
        name: '工作熟手',
        matchConditionCategoryId: '2',
        type: 2,
        parentId: null,
        code: 'work_skilled',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '13',
        name: '口齿伶俐',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'good_talker',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '12',
        name: '没有纹身',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'no_tattoos',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '11',
        name: '不留指甲',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'no_nail',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 15:38:56',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 15:38:56',
        matchConditionId: '10',
        name: '五官端正',
        matchConditionCategoryId: '2',
        type: 0,
        parentId: null,
        code: 'well_featured',
        sort: 0,
        showRemarkFlag: 0,
        remark: null,
        enableFlag: 1,
      },
    ],
  });
  return request({
    url: 'parttime-system-web/match-condition/list',
    params,
  });
}

export function saveMatchScreen(data) {
  return request({
    url: '/parttime-system-web/match-screen/save',
    method: 'POST',
    data,
  });
}

export function saveMatchSort(data) {
  return request({
    url: '/parttime-system-web/match-sort/save',
    method: 'POST',
    data,
  });
}

export function saveMatchAlgorithm(data) {
  return request({
    url: '/parttime-system-web/match-algorithm/save',
    method: 'POST',
    data,
  });
}

export function getMatchSceneList() {
  return Promise.resolve({
    code: 0,
    msg: null,
    data: [
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 12:00:50',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 12:00:50',
        matchSceneId: '3',
        name: '多日用工订单',
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 12:00:24',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 12:00:24',
        matchSceneId: '2',
        name: '模糊日期订单',
        remark: null,
        enableFlag: 1,
      },
      {
        deleteFlag: 0,
        version: 0,
        createBy: '1',
        createByName: null,
        createByAccount: 'admin',
        createByUserType: null,
        createTime: '2024-02-16 12:00:07',
        modifyBy: null,
        modifyByName: null,
        modifyByAccount: null,
        modifyByUserType: null,
        modifyTime: '2024-02-16 12:00:07',
        matchSceneId: '1',
        name: '明确日期订单',
        remark: null,
        enableFlag: 1,
      },
    ],
  });
  return request({
    url: '/parttime-system-web/match-scene/list',
  });
}

export function getScreenConditionList(params) {
  return request({
    url: '/parttime-system-web/match-screen/list',
    params,
  });
}

export function getMatchSortList(params) {
  return request({
    url: '/parttime-system-web/match-sort/list',
    params,
  });
}

export function getMatchAlgorithm(matchSceneId) {
  return request({
    url: `/parttime-system-web/match-algorithm/get-by-match-scene-id/${matchSceneId}`,
    params: {},
  });
}

export function getChannelList(params) {
  return request({
    url: '/parttime-system-web/channel/list',
    params,
  });
}
