import request from 'utils/request';

export function getQuestionTemplateList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-template/list',
    method: 'GET',
    params,
  });
}

export function getQuestionThemeList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-theme/list',
    method: 'GET',
    params,
  });
}

export function getQuestionComponentList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-component/list',
    method: 'GET',
    params,
  });
}

export function getQuestionItemList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-item/complex-list',
    method: 'GET',
    params,
  });
}

export function createQuestion(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/create',
    method: 'POST',
    data: params,
  });
}

export function createQuestionItem(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/item-create',
    method: 'POST',
    data: params,
  });
}

export function createResult(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/result-create',
    method: 'POST',
    data: params,
  });
}

export function createRule(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/rule-create',
    method: 'POST',
    data: params,
  });
}

export function getQuestionDetail(questionId) {
  return request({
    url: `/yinweifuli-marketing-web/question/get-detail-by-id/${questionId}`,
    method: 'GET',
    needLoading: true,
  });
}

export function updateQuestionSecondStep(params) {
  const { questionId, ...realParams } = params;

  return request({
    url: `/yinweifuli-marketing-web/question/update-by-id/${questionId}`,
    method: 'PUT',
    data: realParams,
  });
}

export function releaseQuestion(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/release',
    method: 'PUT',
    data: params,
  });
}
