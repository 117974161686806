import * as Icons from '@ant-design/icons';
import {
  BackTop, Layout, Menu, Spin,
} from 'antd';
import headerLogo from 'assets/images/logo.png';
import classnames from 'classnames';
import config from 'config/config';
import devMenus from 'config/dev-menu';
import staticMenuList from 'config/menus';
import useMobxStores from 'hooks/useMobxStores';
import { debounce, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import React, {
  useEffect, useRef, useState,
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useLocation } from 'react-router-dom';
import AppHeader from 'widgets/header';
import PageTabs from 'widgets/page-tabs';
import styles from './index.module.less';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;
const MenuItem = Menu.Item;

function LayoutView(props) {
  const { appStore } = useMobxStores();

  const { menuList: menus, globalLoading } = appStore;

  const location = useLocation();
  const contentRef = useRef(null);

  const [openKeysList, setOpenKeysList] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => { // 应用启动的前置数据调用接口放这里
  }, []);

  useEffect(() => {
    let mList = [];
    const accessiblePermissions = [];

    async function fetchData() {
      if (config.isStaticMenu) {
        mList = staticMenuList;
      } else {
        try {
          mList = JSON.parse(localStorage.getItem(config.localData.accessibleMenus));
          const recursiveMenu = (arr) => {
            arr = arr.filter((mItem) => {
              mItem.label = mItem.name;
              mItem.routePath = mItem.component;
              if (mItem.childrens?.length) {
                accessiblePermissions.push({ permission: mItem.permission, type: mItem.type });
                const childrenMenus = recursiveMenu(mItem.childrens);
                if (childrenMenus.length) {
                  mItem.children = childrenMenus;
                }
              }
              if (`${mItem.isTreeShow}` === '0') {
                mItem.hidden = true;
              }
              return `${mItem.type}` === '0'; // 0 菜单， 1 按钮
            });
            return arr;
          };
          mList = recursiveMenu(mList);
        } catch (e) {
        }
      }
      appStore.setMenuList([...mList, ...devMenus]);
      const { menuList: list, path2Menu } = appStore;
      findNode(list);
      const curPath = location.pathname;
      setSelectedKeys([getMenuKey(path2Menu[curPath])]);
      setOpenKeys(getParentKeysByPath(curPath));
    }

    fetchData();
  }, []);

  useEffect(() => {
    const { menuList: list, path2Menu } = appStore;
    const { pathname } = location;
    const pKeys = getParentKeysByPath(pathname);
    setSelectedKeys([getMenuKey(path2Menu[pathname])]);
    setOpenKeys(pKeys);
  }, [location.pathname]);

  useEffect(() => {
    const resizeListener = debounce((evt) => {
      appStore.windowWidth = window.innerWidth;
    }, 300);
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const getParentKeysByPath = (pathname) => {
    const { path2Menu } = appStore;

    let parent = {};

    if (path2Menu[pathname]) {
      parent = path2Menu[pathname].parent;
    } else { // 有些页面没有对应的菜单，如(新增/详情页面）
      const module = pathname?.split('/')?.[1];
      const newPathname = Object.keys(path2Menu).find((path) => path.indexOf(module) !== -1);
      parent = path2Menu?.[newPathname]?.parent;
    }

    const pKeys = [];
    while (parent) {
      pKeys.push(getMenuKey(parent));
      parent = parent.parent;
    }
    return pKeys;
  };

  const findNode = (list) => { // 查找含有子菜单的菜单项
    const openKeysList = [];
    list.map((item) => {
      if (item.children) {
        openKeysList.push(`${item.label}_${item.fullPath}`);
        if (item.children.length > 0) {
          findNode(item.children);
        }
      }
    });

    setOpenKeysList(openKeysList);
  };

  const isHidden = (menu) => menu.hidden || (`${menu.isShow}` === '0');

  const getMenuKey = (menu) => `${menu?.label}_${menu?.fullPath}`;

  const renderMenu = (menu) => {
    if (isHidden(menu)) {
      return;
    }
    const menuChildren = menu.children?.filter((child) => !isHidden(child));

    if (isEmpty(menuChildren)) {
      return (
        <MenuItem key={getMenuKey(menu)}>
          <div className={classnames(styles.circle, 'circle')} />
          <Link to={menu.fullPath}>
            {menu.label}
          </Link>
        </MenuItem>
      );
    }
    const menuIcon = menu.icon?.includes?.('iconfont') ? <span className={menu.icon} /> : (Icons[menu.icon] && React.createElement(Icons[menu.icon]));
    return (
      <SubMenu
        title={menu.label}
        key={getMenuKey(menu)}
        icon={menuIcon}
      >
        { menuChildren.map((subMenu) => renderMenu(subMenu)) }
      </SubMenu>
    );
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (openKeysList.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Layout className={styles.app_main} id="js-layout">
      <Sider collapsible className={styles.sider} onCollapse={(collapsed) => document.getElementById('js-layout')?.classList?.[collapsed ? 'add' : 'remove']?.('menu-collapsed')}>
        <div className={styles.imglogo}>
          Logo
        </div>

        <PerfectScrollbar>
          <Menu
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenChange={onOpenChange}
            onSelect={({ selectedKeys }) => setSelectedKeys(selectedKeys)}
          >
            { menus.map((menu, index) => renderMenu(menu)) }
          </Menu>
        </PerfectScrollbar>
      </Sider>

      <Layout className={styles.app_layout}>
        <AppHeader />

        <PageTabs />

        <Content className={styles.app_content}>
          {globalLoading && (<Spin spinning className={styles.spin} />)}
          <div ref={contentRef} id="main_inner" className={styles.main_inner}>
            {props.children}
            <BackTop visibilityHeight={600} target={() => contentRef.current} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default observer(LayoutView);
