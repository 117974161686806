import { makeAutoObservable, observable } from 'mobx';
import { getRoleList, updateEnableFlag } from './user-list-serv';

class Store {
  roleList = []

  orgTree = []

  biz = {}

  constructor() {
    makeAutoObservable(this, {
      roleList: observable.shallow,
      orgTree: observable.shallow,
      biz: observable.shallow,
    });
  }

  async getRoleList() {
    try {
      const { data } = await getRoleList({});
      data.forEach((item) => {
        item.label = item.name;
        item.value = item.roleId;
      });
      this.roleList = data;
    } catch (e) {
    }
  }

  async updateEnableFlag(ids: string[], enableFlag) {
    try {
      await updateEnableFlag({ ids, enableFlag });
      this.biz.refreshListDatas?.(true);
    } catch (e) {
    }
  }
}
export default new Store();
