import request from 'utils/request';

export function getDetail(id: string) {
  return request({
    url: `/yinweifuli-system-web/role/get-by-id/${id}`,
    method: 'GET',
  });
}

export function getRefList(id: string, menuType: number | string) {
  return request({
    url: `/yinweifuli-system-web/role-menu-ref/role/menus/${id}/${menuType}`,
    method: 'GET',
  });
}

export function getMenuTree(params) {
  return request({
    url: '/yinweifuli-system-web/menu/tree',
    method: 'GET',
    params,
  });
}
