import React from 'react';
import { Navigate } from 'react-router-dom';
import aftersale from './aftersale/index';
import customerMgnt from './customer-mgnt/index';
import homeMgnt from './home-mgnt';
import devRoutes from './loc-dev/index';
import merchantMgnt from './merchant-mgnt/index';
import orderMgnt from './order-mgnt/index';
import sysMgnt from './sys-mgnt';
import couponMgnt from './coupon-mgnt/index';
import bizConfig from './biz-config.ts';
// IMPORT-ROUTER

export default [
  {
    path: '/',
    component: () => <Navigate to="/home-mgnt" />,
  },
  ...devRoutes,
  ...sysMgnt,
  ...homeMgnt,
  // ...commodityMgnt,
  // ...merchantMgnt,
  ...orderMgnt,
  // ...aftersale,
  ...customerMgnt,
  ...bizConfig,
  // ...questionnaireMgnt,
  // ...couponMgnt,
  // DESTRUCT-ROUTER
];
