import { ConfigProvider } from 'antd';
import 'assets/styles/index.module.less';
import { Provider } from 'mobx-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'widgets/app';
import favicon from 'assets/images/favicon.png';
import stores from './stores';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/iconfont/iconfont.css';
import 'moment/dist/locale/zh-cn';

const container = document.getElementById('app');
const faviconEle = document.getElementById('favicon');
faviconEle && (faviconEle.href = favicon);

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <ConfigProvider>
    <Provider
      {...stores}
    >
      <App />
    </Provider>
  </ConfigProvider>,
);
