import { makeAutoObservable, observable } from 'mobx';
import {
  getDetail, getOrderList, getAftersaleOrderList, getShopList, getAddressList,
} from './customer-detail-serv';

class Store {
  detail = {}

  orderList = []

  aftersaleOrderList = []

  shopList = []

  addressList = []

  constructor() {
    makeAutoObservable(this, {
      detail: observable.shallow,
      orderList: observable.shallow,
      aftersaleOrderList: observable.shallow,
      shopList: observable.shallow,
      addressList: observable.shallow,
    });
  }

  async getDetail(id) {
    const { data } = await getDetail(id);
    this.detail = data;
  }

  async getOrderList(params) {
    const { data } = await getOrderList(params);
    this.orderList = data?.records || [];
    const shopIds = new Set();
    this.orderList.forEach((order) => shopIds.add(order.shopId));
    this.getRelativeShopList({ shopIds: Array.from(shopIds) });
  }

  async getAftersaleOrderList(params) {
    const { data } = await getAftersaleOrderList(params);
    this.aftersaleOrderList = data?.records || [];
  }

  async getAddressList(params) {
    const { data } = await getAddressList(params);
    this.addressList = data || [];
  }

  async getRelativeShopList(params) {
    const { data } = await getShopList(params);
    this.shopList = data?.records || [];
  }
}
export default new Store();
