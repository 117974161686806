import { makeAutoObservable, observable } from 'mobx';
import { array2tree } from 'utils/utils';
import { getCategoryList } from './category-mgnt-serv';

class Store {
  backTree = [] // 后端树

  frontTree = [] // 前端树

  categoryList = []

  categoryMap = {}

  chosenCategoryId = ''

  commodityListBiz = {}

  constructor() {
    makeAutoObservable(this, {
      backTree: observable.shallow,
      frontTree: observable.shallow,
      categoryList: observable.shallow,
      categoryMap: observable.shallow,
      commodityListBiz: observable.shallow,
    });
  }

  async getCategoryTree() {
    try {
      const { data } = await getCategoryList({ size: 1000, current: 1 });
      const categoryList = data || [];
      const frontList = [];
      const backList = [];
      const categoryMap = {};
      categoryList.sort((a, b) => a.sort - b.sort);
      categoryList.forEach((item) => {
        item.key = item.categoryId;
        item.title = item.name;
        item.parentId = item.parentCategoryId;
        categoryMap[item.key] = item;
        if (item.type === 1) {
          frontList.push(item);
        } else if (item.type === 2) {
          backList.push(item);
        }
      });
      const frontTree = array2tree(frontList, { parentId: 'parentCategoryId', id: 'categoryId', mostTopId: '' });
      const backTree = array2tree(backList, { parentId: 'parentCategoryId', id: 'categoryId', mostTopId: '' });
      const recursiveArr = (arr) => {
        arr.forEach((item, idx) => {
          item.nextId = arr[idx + 1]?.categoryId;
          item.prevId = arr[idx - 1]?.categoryId;
          if (idx === 0) {
            item.isFirstChild = true;
          }
          if (idx === arr.length - 1) {
            item.isLastChild = true;
          }
          Array.isArray(item.children) && recursiveArr(item.children);
        });
      };
      recursiveArr(backTree);
      this.backTree = backTree;
      this.frontTree = frontTree;
      this.categoryList = categoryList;
      this.categoryMap = categoryMap;
    } catch (e) {
    }
  }
}
export default new Store();
