import { makeAutoObservable, observable } from 'mobx';
import { updateEnableFlag } from './role-list-serv';

class Store {
  biz = {} // list page组件提供的属性及方法的对象

  constructor() {
    makeAutoObservable(this, {
      biz: observable.shallow,
    });
  }

  async updateEnableFlag(ids: string[], enableFlag) {
    try {
      await updateEnableFlag({ ids, enableFlag });
      this.biz.refreshListDatas?.(true);
    } catch (e) {
    }
  }
}
export default new Store();
