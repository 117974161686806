import { makeAutoObservable, observable } from 'mobx';
import { sum } from 'lodash';
import Decimal from 'decimal.js';
import { decimalNumToFixed } from 'utils/utils';
import { getDetail } from './order-detail-serv';

class Store {
  detail = {}

  area = []

  constructor() {
    makeAutoObservable(this, {
      detail: observable.shallow,
      area: observable.shallow,
    });
  }

  async getDetail(id) {
    const { data } = await getDetail(id);
    if (data.orderItemVOS?.length) {
      const sumTotalAccount = Decimal.sum(...data.orderItemVOS.map((item) => item.totalPrice)); // 所有商品的原价合计
      const totalDiscount = new Decimal(data.totalAmount).minus(data.totalActualUnitPrice); // 优惠总额
      data.orderItemVOS.forEach((item) => {
        const discountSkuPrice = Decimal.div(Decimal.div(totalDiscount, sumTotalAccount).mul(item.totalPrice), item.quantity);
        item.commoditySkuDiscountPrice = decimalNumToFixed(discountSkuPrice);
        const skuPrice = new Decimal(item.commoditySkuPrice);
        const actualUnitPrice = skuPrice.minus(discountSkuPrice);
        item.actualUnitPrice = decimalNumToFixed(actualUnitPrice);
        item.discountTotalPrice = data.orderItemVOS.length === 1 ? decimalNumToFixed(Decimal.mul(discountSkuPrice, item.quantity)) : totalDiscount.toString();
        item.actualTotalPrice = data.orderItemVOS.length === 1 ? data.totalActualUnitPrice : Decimal.mul(actualUnitPrice, item.quantity).toString();
      });
    }
    this.detail = data;
  }
}
export default new Store();
