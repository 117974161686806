import { groupBy } from 'lodash';
import { makeAutoObservable, observable } from 'mobx';
import {
  createQuestion, createQuestionItem, createResult, createRule, getQuestionComponentList, getQuestionDetail, getQuestionItemList, getQuestionTemplateList, getQuestionThemeList, releaseQuestion, updateQuestionSecondStep,
} from './questionnaire-add-serv';

// import { questionnaireComponentMap } from 'pages/consts';

class Store {
  questionTemplateList = []

  questionThemeList = []

  questionComponentList = []

  questionItemList = []

  constructor() {
    makeAutoObservable(this, {
      questionTemplateList: observable.shallow,
      questionThemeList: observable.shallow,
      questionComponentList: observable.shallow,
      questionItemList: observable.shallow,
    });
  }

  async releaseQuestion(params) {
    await releaseQuestion(params);
  }

  async getQuestionTemplateList(params) {
    const { data } = await getQuestionTemplateList(params);
    this.questionTemplateList = data;
  }

  async getQuestionThemeList(params) {
    const { data } = await getQuestionThemeList(params);
    this.questionThemeList = data;
    return data;
  }

  async getQuestionComponentList(params) {
    const { data } = await getQuestionComponentList(params);
    this.questionComponentList = groupBy(data, 'category');
  }

  async getQuestionItemList(params) {
    const { data } = await getQuestionItemList(params);
    this.questionItemList = data;
    return data;
  }

  async getQuestionDetail(questionId) {
    const { data } = await getQuestionDetail(questionId);
    return data;
  }

  async updateQuestionSecondStep(params) {
    await updateQuestionSecondStep(params);
  }

  async createQuestion(params) {
    const { data } = await createQuestion(params);
    return data; // data === questionId
  }

  async createQuestionItem(params) {
    await createQuestionItem(params);
  }

  async createResult(params) {
    await createResult(params);
  }

  async createRule(params) {
    await createRule(params);
  }
}
export default new Store();
