import { makeAutoObservable, observable } from 'mobx';
import {
  getRoleListOfUser,
  createBusinessAccount, updateEnableFlag, getExcludeBusinessAccountList,
} from './user-assign-perm-serv';

class Store {
  orgTree = []

  roleList = []

  roleListOfUser = []

  chosenOrgIds = []

  chosenRoleIds = []

  parentKeysOfChosenOrg = []

  excludeBusinessAccountList = []

  orgMap = {} // orgId -> orgObj

  initState() {
    this.roleListOfUser = [];
    this.excludeBusinessAccountList = [];
  }

  constructor() {
    makeAutoObservable(this, {
      orgTree: observable.shallow,
      roleList: observable.shallow,
      roleListOfUser: observable.shallow,
      chosenOrgIds: observable.shallow,
      chosenRoleIds: observable.shallow,
      parentKeysOfChosenOrg: observable.shallow,
      orgMap: observable.shallow,
      excludeBusinessAccountList: observable.shallow,
    });
  }

  async getRoleList() {
    try {
      const { data } = await getRoleListOfUser();
      this.roleList = data;
    } catch (e) {
    }
  }

  async getRoleListOfUser(userId) {
    try {
      const { data } = await getRoleListOfUser(userId);
      this.chosenRoleIds = data.map((item) => item.roleId);
      this.roleListOfUser = data;
    } catch (e) {
    }
  }

  async getExcludeBusinessAccountList(params) {
    try {
      const { data } = await getExcludeBusinessAccountList(params);

      this.excludeBusinessAccountList = data?.map((item) => ({
        label: item.name,
        value: item.businessAccountId,
      })) || [];
    } catch (e) {
    }
  }

  async createBusinessAccount(params) {
    await createBusinessAccount(params);
  }

  async updateEnableFlag(params) {
    await updateEnableFlag(params);
  }
}
export default new Store();
