import request from 'utils/request';

export function getDetail(id) {
  return request({
    url: `/yinweifuli-shop-web/commodity/get-by-id/${id}`,
  });
}

export function getBrandList(params) {
  return request({
    url: '/yinweifuli-shop-web/brand/list',
    params,
  });
}

export function getShopList(params) {
  return request({
    url: '/yinweifuli-shop-web/shop/find-simple-page',
    params,
  });
}

export function getCategoryList(params) {
  return request({
    url: '/yinweifuli-shop-web/category/list',
    method: 'GET',
    params,
  });
}
