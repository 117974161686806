import request from 'utils/request';

export function getDetail(id: string) {
  return request({
    url: `/yinweifuli-system-web/role/get-by-id/${id}`,
    method: 'GET',
    needLoading: true,
  });
}

export function getRefList(id: string, menuType: string | number) {
  return request({
    url: `/yinweifuli-system-web/role-menu-ref/role/menus/${id}/${menuType}`,
    method: 'GET',
    needLoading: true,
  });
}

export function getMenuTree() {
  return request({
    url: '/yinweifuli-system-web/menu/tree',
    method: 'GET',
    params: { enableFlag: 1, type: 0 },
    needLoading: true,
  });
}

export function addRole(params: any) {
  return request({
    url: '/yinweifuli-system-web/role/create',
    method: 'POST',
    data: params,
  });
}

export function updateRole(id: string, params: any) {
  return request({
    url: `/yinweifuli-system-web/role/update-by-id/${id}`,
    method: 'PUT',
    data: params,
  });
}
