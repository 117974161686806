import { makeAutoObservable, observable } from 'mobx';
import { array2tree } from 'utils/utils';
import { getCategoryList } from './commodity-list-serv';

class Store {
  frontTree = []

  backTree = []

  biz = {}

  constructor() {
    makeAutoObservable(this, {
      frontTree: observable.shallow,
      backTree: observable.shallow,
      biz: observable.shallow,
    });
  }

  async getCategoryTree() {
    try {
      const { data } = await getCategoryList({ size: 1000, current: 1 });
      const categoryList = data || [];
      const frontList = [];
      const backList = [];
      const categoryMap = {};
      categoryList.sort((a, b) => a.sort - b.sort);
      categoryList.forEach((item) => {
        item.key = item.categoryId;
        item.title = item.name;
        item.parentId = item.parentCategoryId;
        categoryMap[item.key] = item;
        if (item.type === 1) {
          frontList.push(item);
        } else if (item.type === 2) {
          backList.push(item);
        }
      });
      const frontTree = array2tree(frontList, { parentId: 'parentCategoryId', id: 'categoryId', mostTopId: '' });
      const backTree = array2tree(backList, { parentId: 'parentCategoryId', id: 'categoryId', mostTopId: '' });
      const recursiveArr = (arr) => {
        arr.forEach((item, idx) => {
          // item.nextId = arr[idx + 1]?.categoryId;
          // item.prevId = arr[idx - 1]?.categoryId;
          // if (idx === 0) {
          //   item.isFirstChild = true;
          // }
          // if (idx === arr.length - 1) {
          //   item.isLastChild = true;
          // }
          item.selectable = (item.type === 2 && !item.children?.length); // 后端类目只能选择末级节点
          Array.isArray(item.children) && recursiveArr(item.children);
        });
      };
      recursiveArr(backTree);
      this.backTree = backTree;
      this.frontTree = frontTree;
    } catch (e) {
    }
  }
}
export default new Store();
