import request from 'utils/request';

export function startOrPauseQuestion(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/start-or-pause',
    method: 'PUT',
    data: params,
  });
}

export function endUpQuestion(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/end-up',
    method: 'PUT',
    data: params,
  });
}

export function releaseQuestion(params) {
  return request({
    url: '/yinweifuli-marketing-web/question/release',
    method: 'PUT',
    data: params,
  });
}

export function getQuestionChannelList(params) {
  return request({
    url: '/yinweifuli-marketing-web/question-channel/list',
    method: 'GET',
    params,
  });
}
