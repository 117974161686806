import { makeAutoObservable, observable } from 'mobx';
import { getChannelList, getDistributeMatchNum } from './order-list-serv';

class Store {
  biz = {}

  userBiz = {}

  channelList = []

  availableWorkersForDistribution = {}

  constructor() {
    makeAutoObservable(this, {
      biz: observable.shallow,
      userBiz: observable.shallow,
      availableWorkersForDistribution: observable.shallow,
    });
  }

  async getChannelList() {
    const { data } = await getChannelList();
    this.channelList = data || [];
  }

  async getDistributeMatchNum(params) {
    const { data } = await getDistributeMatchNum(params);
    this.availableWorkersForDistribution = data;
  }
}
export default new Store();
