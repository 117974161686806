import axios from 'axios';
import Cookie from 'js-cookie';
import { cloneDeep } from 'lodash';
import qs from 'qs';
import appStore from 'widgets/app/app-store';

import siteConfig from 'config/config';

import { message } from 'antd';

axios.defaults.timeout = 180000;
// axios.defaults.baseURL = '';
axios.defaults.withCredentials = true;

// 用于计算控制loading的接口数量
let loadingNum = 0;

/**
 * 如果登录了，就把token写入header
 * */
// 请求拦截
axios.interceptors.request.use(
  (config) => {
    const { needLoading } = config;

    if (needLoading) {
      loadingNum++;
      appStore.globalLoading = true;
    }

    // url配置
    config.url = siteConfig.apiAppName(config.url, config.app);

    // 设置响应类型为json
    config.responseType = config.responseType || 'json';
    // 取出headers
    let headers = cloneDeep(config.headers);

    const auth = Cookie.get(siteConfig.cookie.authName);

    // 不传入，则默认为空对象
    headers = headers || {};
    // 解决ie不重新请求问题
    headers = {
      ...headers,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: -1,
      Scene: `${location.hash}, ${encodeURIComponent(document.body.querySelector('[class*=page_tab] [class*=active]')?.textContent || '')}`,
    };
    if (auth) {
      headers.Authorization = `Bearer ${auth}`;
    }

    // Content-type默认为表单提交
    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json';
    }

    config.headers = headers;

    const evt = new CustomEvent('axios_request', { detail: config.url });
    window.dispatchEvent(evt);
    return config;
  },
  (error) => {
    // 对发出请求时错误做些什么
    Promise.reject(error);
  },
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    const {
      data, headers, status, config,
    } = response;

    // 响应成功
    if (config.needLoading) {
      loadingNum--;

      loadingNum = loadingNum < 0 ? 0 : loadingNum;

      if (!loadingNum) {
        appStore.globalLoading = false;
      }
    }

    if (config.url.includes('aliyuncs.com')) { // 上传到oss
      return response;
    }

    if (headers?.['content-disposition']?.includes('attachment')) {
      if (status === 200) {
        const link = document.createElement('a');
        if (data) {
          const blobUrl = URL.createObjectURL(data);
          link.download = config.fileName ? config.fileName : qs.parse(headers['content-disposition'].split(';')[1]).filename;
          link.href = blobUrl;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        message.error('Network Error');
        return Promise.reject(new Error('请求文档数据失败'));
      }

      return response.data;
    }

    // 与合作框架的导入有关
    if (data.toString() === '[object Blob]' && data.type === 'application/json') {
      const fr = new FileReader();

      fr.onload = (e) => {
        response.data = JSON.parse(e.target.result);
      };

      fr.readAsText(data);

    // return response.data; // todo 用异步的方式走这里才可以返回data
    } else {
      const { code, msg } = data || {};
      if (`${code}` !== '0') {
        const errMsg = msg || 'Network Error';
        if (errMsg.includes('请先登录') || errMsg.includes('重新登录') || errMsg.includes('错误的token')) {
          location.hash = '/login';
          location.reload();
          return;
        }
        message.error(errMsg);
        return Promise.reject(new Error('请求失败'));
      }
      config.successMsg && message.success(config.successMsg);

      return response.data;
    }
  },
  (error) => { // 对响应错误做点什么
    const { needLoading, handleError } = error?.config || {};

    if (needLoading) {
      loadingNum--;

      loadingNum = loadingNum < 0 ? 0 : loadingNum;
      if (!loadingNum) {
        appStore.globalLoading = false;
      }
    }

    const { response, message: errorMessage } = error || {};
    if (errorMessage === 'cancel-by-handle') { // 手动取消，有时当前一个请求未完成，但用户点击按钮又重新请求，这时要把上一个请求取消
      return Promise.reject(errorMessage);
    }
    const { status, data } = response || {};
    if (status === 401) {
      if (data?.msg?.includes?.('请先登录') || data?.msg?.includes('重新登录')) {
        location.hash = '/login';
        location.reload();
        return;
      }
    }

    const { msg, error: errorMsg } = data || {};
    const errMsg = msg || errorMsg || '接口错误';

    if (errMsg) {
      if (handleError) {
        handleError();
      } else {
        message.error({
        // className: styles.message,
          content: errMsg,
          // duration: 0,
          onClick: () => { message.destroy(); },
        });
      }
    } else if (!errMsg.includes?.('权限不足')) {
      message.error(errMsg);
    }

    return Promise.reject(data);
  },
);
window.request = axios; // todo ? 扫码授权页面使用， 考虑webpack打包从页面入口？
export default axios;
