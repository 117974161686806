import appStore from 'widgets/app/app-store';

const modules = {
  appStore,
};
const storeModules = import.meta.glob(['pages/**/*-store.js', 'pages/**/*-store.ts'], { eager: true });
console.log('storeModules:', storeModules);
for (const path in storeModules) {
  const pathParts = path.split('/');
  const modelKey = pathParts[pathParts.length - 1].replace(/\.(js|ts)/, '').replace(/-([a-z])/g, (match) => match[1].toUpperCase()); // 文件名作为key
  modules[modelKey] = storeModules[path].default;
}
export default modules;
