import { makeAutoObservable } from 'mobx';
import { getAsyncData } from './merchant-list-serv';

class Store {
  test = ''

  constructor() {
    makeAutoObservable(this);
  }

  async getAsyncData() {
    const { success, data } = await getAsyncData();
    if (success) {
      this.test = data || [];
    }
  }
}
export default new Store();
