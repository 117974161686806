import { LoadingOutlined } from '@ant-design/icons';
import config from 'config/config';
import Cookie from 'js-cookie';
// moment 语言设置为中文
import moment from 'moment';
import 'moment/locale/zh-cn';
import Login from 'pages/login';
import React, { Suspense, useEffect } from 'react';
import KeepAlive, { AliveScope } from 'react-activation';
import {
  HashRouter, Navigate, Route, Routes, useNavigate,
} from 'react-router-dom';
import routers from 'router/index';
import Layout from 'widgets/layout';
import styles from './index.module.less';

moment.locale('zh-cn');

function Redirect({ path }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(path);
  }, []);
  return <></>;
}

function renderRouteItem(route) {
  const { path, cache } = route;
  function wrapLoading(ele) {
    return (
      <Suspense fallback={(
        <div className={styles.loading_wrapper}>
          <LoadingOutlined />
          正在加载中...
        </div>
      )}
      >
        {ele}
      </Suspense>
    );
  }
  return cache ? (
    <KeepAlive
      name={path}
      cacheKey={path}
    >
      {wrapLoading(<route.component />)}
    </KeepAlive>
  ) : wrapLoading(<route.component />);
}

function AppView() {
  return (
    <HashRouter>
      <AliveScope>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={React.createElement(() => {
              if (config.isLogin && !Cookie.get(config.cookie.authName)) { // 如果还没登陆
                return (
                  <Navigate to="/login" />
                );
              }
              return (
                <Layout>
                  <Routes>
                    {
                      routers.map((route) => {
                        const { path } = route;
                        return (
                          <Route
                            path={path}
                            key={path}
                            element={renderRouteItem(route)}
                          />
                        );
                      })
                    }
                    <Route
                      path="*"
                      element={<Redirect path="/404" />}
                    />
                  </Routes>
                </Layout>
              );
            })}
          />
        </Routes>
      </AliveScope>
    </HashRouter>
  );
}

export default AppView;
