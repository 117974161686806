import request from 'utils/request';

export function getDetail(id) {
  return request({
    url: `/yinweifuli-system-web/user/get-by-id/${id}`,
    method: 'GET',
  });
}

export function getRoleList(params) {
  return request({
    url: '/yinweifuli-system-web/role/list',
    method: 'GET',
    params,
  });
}
