import React from 'react';

export default [
  {
    path: '/home-mgnt',
    component: React.lazy(() => import('pages/home-mgnt/index')),
  },
  {
    path: '/ad-mgnt/ad-list',
    component: React.lazy(() => import('pages/home-mgnt/index')),
  },
  {
    path: '/ad-mgnt/schedule-plan',
    component: React.lazy(() => import('pages/home-mgnt/index')),
  },
  {
    path: '/ad-mgnt/ad-wait-list',
    component: React.lazy(() => import('pages/home-mgnt/index')),
  },
  {
    path: '/ad-mgnt/businessmen-mgnt',
    component: React.lazy(() => import('pages/home-mgnt/index')),
  },
];
